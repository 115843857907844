import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {COUNTRY, COUNTRY_API_URL, GET_ALL_COUNTRY_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  CountryErrors       : {},
  permission: permission?.country ?? [],
  CountryShowMessage  : null,
  CountryResult       : [],
  CountryButtonSpinner : false,
  CountryTableLoading : true,
  CountryAddDrawer    : false,
  CountryEditData     : [],
  CountryButtonAndModelLabel  : setLocale('countries.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    DrawerStatus: 0,
};

export const createCountry = createAsyncThunk(
    "createCountry",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, COUNTRY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCountry = createAsyncThunk(
    "getCountry",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_COUNTRY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteCountry = createAsyncThunk(
  "deleteCountry",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, COUNTRY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageCountrySlice = createSlice({
  name: COUNTRY,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.CountryErrors = {};
    },
    CountryAddDrawerStatus: (state, action) => {
      // state.CountryErrors      = {};
      state.CountryAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.CountryEditData    = [];
      state.CountryButtonAndModelLabel = setLocale('countries.add');
    },
    CountryEditWithDrawerStatus: (state, action) => {
      // state.CountryErrors = {};
      state.CountryAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.CountryEditData = action.payload.data;
      state.CountryButtonAndModelLabel = setLocale('countries.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCountry.pending, (state, action) => {
        state.DrawerStatus = 0
        state.CountryButtonSpinner = true;
      }).addCase(createCountry.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.CountryButtonSpinner = false;
        state.CountryErrors = {}
      }).addCase(createCountry.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.CountryShowMessage = true;
        state.CountryButtonSpinner = false;
        state.CountryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getCountry.pending, (state, action) => {
        state.CountryButtonSpinner = true;
        state.CountryTableLoading = true;
      }).addCase(getCountry.fulfilled, (state, action) => {
        state.CountryButtonSpinner = false;
        state.CountryTableLoading = false;
        state.CountryResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getCountry.rejected, (state, action) => {
        state.CountryShowMessage = true; // Set the showMessage flag to display the errors
        state.CountryButtonSpinner = false;
        state.CountryTableLoading = false;
        state.CountryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(deleteCountry.pending, (state, action) => {

      }).addCase(deleteCountry.fulfilled, (state, action) => {

      }).addCase(deleteCountry.rejected, (state, action) => {

      });
  },
});

export const {onCloseError,setColumnSearch, CountryAddDrawerStatus, CountryEditWithDrawerStatus, updateSortFilters } = manageCountrySlice.actions;

export default manageCountrySlice.reducer;
