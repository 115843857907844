import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MANAGE_USER, MANAGE_USER_API_URL, MANAGE_USER_FILTER_API_URL } from "constants/AppConstants";
import CommonService from "services/CommonService/CommonService";
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

export const initialState = {
  ManageUserErrors: {},
  ManageUserShowMessage: null,
  ManageUserResult: [],
  ManageUserButtonSpiner: false,
  ManageUserAddDrawer: false,
  ManageUserEditData: [],
  ManageUserButtonAndModelLabel: setLocale("users.add"),
};

export const createManageUser = createAsyncThunk(
  "createManageUser",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(
        data,
        MANAGE_USER_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getManageUser = createAsyncThunk("getManageUser", async (data) => {
  try {
    const response = await CommonService.getAll(MANAGE_USER_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const getManageUserFilter = createAsyncThunk("getManageUserFilter", async (data) => {
  try {
    const response = await CommonService.getAllPost(data, MANAGE_USER_FILTER_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const deleteManageUser = createAsyncThunk(
  "deleteManageUser",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, MANAGE_USER_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageManageUserSlice = createSlice({
  name: MANAGE_USER,
  initialState,
  reducers: {
    ManageUserAddDrawerStatus: (state, action) => {
      state.ManageUserErrors = {};
      state.ManageUserAddDrawer = action.payload;
      state.ManageUserEditData = [];
      state.ManageUserButtonAndModelLabel = setLocale("users.add");
    },
    ManageUserEditWithDrawerStatus: (state, action) => {
      state.ManageUserErrors = {};
      state.ManageUserAddDrawer = true;
      state.ManageUserEditData = action.payload;
      state.ManageUserButtonAndModelLabel = setLocale("users.edit");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createManageUser.pending, (state, action) => {
        state.ManageUserButtonSpiner = true;
      })
      .addCase(createManageUser.fulfilled, (state, action) => {
        state.ManageUserButtonSpiner = false;
        state.ManageUserErrors = {};
      })
      .addCase(createManageUser.rejected, (state, action) => {
        state.ManageUserErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ManageUserShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageUserButtonSpiner = false;
      })
      .addCase(getManageUser.pending, (state, action) => {
        state.ManageUserButtonSpiner = true;
      })
      .addCase(getManageUser.fulfilled, (state, action) => {
        state.ManageUserButtonSpiner = false;
        state.ManageUserResult = action.payload;
      })
      .addCase(getManageUser.rejected, (state, action) => {
        state.ManageUserErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ManageUserShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageUserButtonSpiner = false;
      })
      .addCase(getManageUserFilter.pending, (state, action) => {
        state.ManageUserButtonSpiner = true;
      })
      .addCase(getManageUserFilter.fulfilled, (state, action) => {
        state.ManageUserButtonSpiner = false;
        state.ManageUserResult = action.payload;
      })
      .addCase(getManageUserFilter.rejected, (state, action) => {
        state.ManageUserErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ManageUserShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageUserButtonSpiner = false;
      });
  },
});

export const { ManageUserAddDrawerStatus, ManageUserEditWithDrawerStatus } =
  manageManageUserSlice.actions;

export default manageManageUserSlice.reducer;
