import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CommonService from 'services/CommonService/CommonService';
// import TransportEmailService from 'services/TransportEmailManagement/TransportEmailService';
import {SEND_NOTIFICATION, SEND_NOTIFICATION_API_URL, GET_ALL_SEND_NOTIFICATION_API_URL} from 'constants/AppConstants';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
// import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
// const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));
export const initialState = {
    loading: false,
    tableLoading:true,
    buses: [],
    Province: '',
    addressTypes: '',
    parentOrganizations: '',
    guardians: [],
    organizationRoles: '',
    errors: {},
    showMessage: false,
    openModal:false,
    editData:null,
    busPagination: {
		current: 1,
		pageSize: 10,
		total: 0,
	},
    routeBusStudent: [],
};

export const sendMail = createAsyncThunk(
      "sendMail",
    async (data) => {
      try {
        const response = await CommonService.createAndUpdate(data, SEND_NOTIFICATION_API_URL);
        return response;
      } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
      }
    }
  );

export const manageSendNotificationSlice = createSlice({
    name: SEND_NOTIFICATION,
    initialState,
    reducers: {
        clearErrors: (state) => {
            state.errors = {};
        },
        setOpenModal:(state)=>{
            state.openModal =true
            state.editData = null

        },
        setCloseModal:(state)=>{
            state.openModal =false
            state.editData = null
        },
        setEditData: (state, action) => {
            state.openModal =true
            state.editData = action.payload;
   
         },
         clearState:(state) =>{
            state.editData = null;
            // state.routeBusStudent = [];
         }
    },
    extraReducers: (builder) => {
        builder
        .addCase(sendMail.fulfilled, (state, action) => {
            state.loading = false
            state.tableLoading = false
            state.errors = {}
            state.showMessage = false
            state.openModal =false
        })
        .addCase(sendMail.rejected, (state, action) => {
            state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
            state.showMessage = true; // Set the showMessage flag to display the errors
            state.loading = false;
            // state.routeBusStudent = [];
        })
    },
})

export const {   
  setOpenModal,
  setCloseModal,
  clearErrors,
  setEditData,
  clearState } = manageSendNotificationSlice.actions;

export default manageSendNotificationSlice.reducer;