import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {MANAGE_TRANSPORT, MANAGE_TRANSPORT_API_URL, GET_ALL_MANAGE_TRANSPORT_API_URL,GET_ALL_ROUTE_BUS_STUDENTS_URL,GET_BUS_STUDENT_EDIT_DATA_URL,DELETE_BUS_STUDENT_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ManageTransportErrors       : {},
  permission: permission?.manage_transport ?? [],
  ManageTransportShowMessage  : null,
  ManageTransportResult       : [],
  ManageTransportButtonSpinner : false,
  ManageTransportTableLoading : true,
  ManageTransportAddDrawer    : false,
  ManageTransportUpdateDrawer    : false,
  ManageTransportEditData     : [],
  ManageTransportButtonAndModelLabel  : setLocale('transport.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewManageTransportData: [],
    ViewManageTransportLoader: true,
    routeBusStudent: [],
    busStudentEditData:[],
    students:[],
     DrawerStatus: 0,
};

export const createManageTransport = createAsyncThunk(
    "createManageTransport",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, MANAGE_TRANSPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getManageTransport = createAsyncThunk(
    "getManageTransport",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_MANAGE_TRANSPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const getAllRouteBusStudent = createAsyncThunk(
    "getAllRouteBusStudent",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_ROUTE_BUS_STUDENTS_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewManageTransport = createAsyncThunk(
  "viewManageTransport",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, MANAGE_TRANSPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteManageTransport = createAsyncThunk(
  "deleteManageTransport",
  async (data) => {
    try {
      const response = await CommonService.postData(data,DELETE_BUS_STUDENT_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const getBusStudentEditData = createAsyncThunk('getBusStudentEditData', async (data) => {

  try {
      const response = await CommonService.getAllPost(data,GET_BUS_STUDENT_EDIT_DATA_URL);
      return response;
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const manageManageTransportSlice = createSlice({
  name: MANAGE_TRANSPORT,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ManageTransportErrors = {};
    },
    ManageTransportAddDrawerStatus: (state, action) => {
      // state.ManageTransportErrors      = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageTransportAddDrawer   = action.payload.status;
      state.ManageTransportEditData    = [];
      state.ManageTransportButtonAndModelLabel = setLocale('transport.add');
    },
    ManageTransportUpdateDrawerStatus: (state, action) => {
      // state.ManageTransportErrors      = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageTransportUpdateDrawer   = action.payload.status;
      state.ManageTransportEditData    = [];
      state.busStudentEditData = [];
      state.student = [];
      state.ManageTransportButtonAndModelLabel = setLocale('transport.add');
    },
    ManageTransportEditWithDrawerStatus: (state, action) => {
      // state.ManageTransportErrors = {};
      state.ManageTransportUpdateDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageTransportEditData = {
        ...action.payload.data,
        is_editable: 1
      };
      state.busStudentEditData = [];
      state.student = [];
      state.ManageTransportButtonAndModelLabel = setLocale('transport.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createManageTransport.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ManageTransportButtonSpinner = true;
      }).addCase(createManageTransport.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ManageTransportButtonSpinner = false;
        state.ManageTransportErrors = {}
      }).addCase(createManageTransport.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ManageTransportShowMessage = true;
        state.ManageTransportButtonSpinner = false;
        state.ManageTransportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getManageTransport.pending, (state, action) => {
        state.ManageTransportButtonSpinner = true;
        state.ManageTransportTableLoading = true;
      }).addCase(getManageTransport.fulfilled, (state, action) => {
        state.ManageTransportButtonSpinner = false;
        state.ManageTransportTableLoading = false;
        state.ManageTransportResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getManageTransport.rejected, (state, action) => {
        state.ManageTransportShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageTransportButtonSpinner = false;
        state.ManageTransportTableLoading = false;
        state.ManageTransportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getAllRouteBusStudent.fulfilled, (state, action) => {

        state.routeBusStudent = action.payload.data;
    })
    .addCase(getAllRouteBusStudent.rejected, (state, action) => {
        state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.ManageTransportShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageTransportTableLoading = false
    })
      .addCase(viewManageTransport.pending, (state, action) => {
        state.ViewManageTransportLoader = true;
        state.ViewManageTransportData = [];
      }).addCase(viewManageTransport.fulfilled, (state, action) => {
        state.ViewManageTransportLoader = false;
        state.ViewManageTransportData = action.payload;
      }).addCase(viewManageTransport.rejected, (state, action) => {
        state.ViewManageTransportLoader = false;
        state.ViewManageTransportData = [];
      })
      .addCase(deleteManageTransport.pending, (state, action) => {

      }).addCase(deleteManageTransport.fulfilled, (state, action) => {

      }).addCase(deleteManageTransport.rejected, (state, action) => {

      })
      .addCase(getBusStudentEditData.fulfilled, (state, action) => {

        state.busStudentEditData = action.payload.data;
        state.students = action.payload.data.students;
      })
      .addCase(getBusStudentEditData.rejected, (state, action) => {
          state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
          state.showMessage = true; // Set the showMessage flag to display the errors
          state.loading = false
      });
  },
});

export const {onCloseError, setColumnSearch, ManageTransportAddDrawerStatus,ManageTransportUpdateDrawerStatus, ManageTransportEditWithDrawerStatus, updateSortFilters } = manageManageTransportSlice.actions;

export default manageManageTransportSlice.reducer;
