import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SCHOOL_TYPES, SCHOOL_TYPES_API_URL, GET_ALL_SCHOOL_TYPES_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  SchoolTypeErrors: {},
  permission: permission?.school_type ?? [],
  SchoolTypeShowMessage: null,
  SchoolTypeResult: [],
  SchoolTypeButtonSpinner: false,
  SchoolTypeTableLoading: true,
  SchoolTypeAddDrawer: false,
  SchoolTypeEditData: [],
  SchoolTypeButtonAndModelLabel: setLocale('school_type.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  DrawerStatus: 0,
};

export const createSchoolType = createAsyncThunk(
  "createSchoolType",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, SCHOOL_TYPES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getSchoolType = createAsyncThunk(
  "getSchoolType",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_SCHOOL_TYPES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteSchoolType = createAsyncThunk(
  "deleteSchoolType",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, SCHOOL_TYPES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageSchoolTypeSlice = createSlice({
  name: SCHOOL_TYPES,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.SchoolTypeErrors = {};
    },
    SchoolTypeAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.CourseButtonSpinner = false;
      }
      // state.SchoolTypeErrors = {};
      state.SchoolTypeAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.SchoolTypeEditData = [];
      state.SchoolTypeButtonAndModelLabel = setLocale('school_type.add');
    },
    SchoolTypeEditWithDrawerStatus: (state, action) => {
      // state.SchoolTypeErrors = {};
      state.SchoolTypeAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.SchoolTypeEditData = action.payload.data;
      state.SchoolTypeButtonAndModelLabel = setLocale('school_type.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSchoolType.pending, (state, action) => {
        state.DrawerStatus = 0
        state.SchoolTypeButtonSpinner = true;
      }).addCase(createSchoolType.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.SchoolTypeButtonSpinner = false;
        state.SchoolTypeErrors = {}
      }).addCase(createSchoolType.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.SchoolTypeShowMessage = true;
        state.SchoolTypeButtonSpinner = false;
        state.SchoolTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getSchoolType.pending, (state, action) => {
        state.SchoolTypeButtonSpinner = true;
        state.SchoolTypeTableLoading = true;
      }).addCase(getSchoolType.fulfilled, (state, action) => {
        state.SchoolTypeButtonSpinner = false;
        state.SchoolTypeTableLoading = false;
        state.SchoolTypeResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getSchoolType.rejected, (state, action) => {
        state.SchoolTypeShowMessage = true; // Set the showMessage flag to display the errors
        state.SchoolTypeButtonSpinner = false;
        state.SchoolTypeTableLoading = false;
        state.SchoolTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(deleteSchoolType.pending, (state, action) => {

      }).addCase(deleteSchoolType.fulfilled, (state, action) => {

      }).addCase(deleteSchoolType.rejected, (state, action) => {

      });
  },
});

export const { onCloseError, setColumnSearch, SchoolTypeAddDrawerStatus, SchoolTypeEditWithDrawerStatus, updateSortFilters } = manageSchoolTypeSlice.actions;

export default manageSchoolTypeSlice.reducer;
