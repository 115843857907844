import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STUDENT_GRADE_BOOK_SLICE } from 'constants/AppConstants';
import { 
    GET_CLASS_STUDENTS_FOR_GRADES,
    ADD_COURSE_GRADE_VATEGORY,
    ADD_STUDENT_OBTAIN_MARKS,
    FINALIZED_COURSE_GRADE_CATEGORY,
    VIEW_STUDENT_GRADE_DETAIL
 } from 'constants/student-grade-book/index';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
    loading: false,
    classStudentsForGrades:[],
    activeStudents:[],

    loadStudentGrades:false,
    studentGradeHeader:[],
    studentGradeBody:[],
    courseGradeAssignments:[]
}
/** get ClassStudents for Grades */
export const getClassStudentsForGrades = createAsyncThunk('getClassStudentsForGrades', async (data) => {
    try {
        return await CommonService.postData(data, GET_CLASS_STUDENTS_FOR_GRADES);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
export const addCourseGradeBookCategory = createAsyncThunk('addCourseGradeBookCategory', async (data) => {
    try {
        return await CommonService.postData(data, ADD_COURSE_GRADE_VATEGORY);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
/**add and update student obtained Marks */
export const addStudentObtainedMarks = createAsyncThunk('addStudentObtainedMarks', async (data) => {    
    try {
        return await CommonService.postData(data, ADD_STUDENT_OBTAIN_MARKS);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
/** Finalized Grade course category */
export const finalizedCourseGradeCategory = createAsyncThunk('finalizedCourseGradeCategory', async (data) => {
    try {
        return await CommonService.postData(data, FINALIZED_COURSE_GRADE_CATEGORY);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
export const viewStudentGradeDetail = createAsyncThunk('viewStudentGradeDetail', async (data) => {
    try {
        return await CommonService.getData(data, VIEW_STUDENT_GRADE_DETAIL);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})

export const studentGradeBookSlice = createSlice({
    name: STUDENT_GRADE_BOOK_SLICE,
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
        builder 
        .addCase(addCourseGradeBookCategory.pending, (state) => {
            state.saveBtnLoading = true
        }).addCase(addCourseGradeBookCategory.fulfilled, (state) => {
            state.saveBtnLoading = false
            state.addNewGradeCategoryModal = false
        }).addCase(addCourseGradeBookCategory.rejected, (state) => {
            state.saveBtnLoading = false
        })
        .addCase(getClassStudentsForGrades.pending, (state) => {
            state.loadStudentGrades = true
            // state.classStudentsForGrades = [];
            // state.activeStudents = [];
            state.studentGradeHeader = []
            state.studentGradeBody = []
            state.courseGradeAssignments = []
        }).addCase(getClassStudentsForGrades.fulfilled,(state,action)=>{
            state.loadStudentGrades = false
            state.studentGradeHeader = action.payload.data.header
            state.studentGradeBody = action.payload.data.body
            state.courseGradeAssignments = action.payload.data.courseGradeAssignments

            // if(action.payload.data[0]?.id){
            //     state.classStudentsForGrades = action.payload.data
            // }
            // state.activeStudents = action.payload.data[0]?.students
        }).addCase(getClassStudentsForGrades.rejected, (state) => {
            state.loadStudentGrades = false           
        })
        .addCase(viewStudentGradeDetail.pending, (state) => {            
        }).addCase(viewStudentGradeDetail.fulfilled, (state,action)=>{   
            console.log(action.payload)         
        }).addCase(viewStudentGradeDetail.rejected, (state) => {            
        })
    },
})

export const {

} = studentGradeBookSlice.actions

export default studentGradeBookSlice.reducer