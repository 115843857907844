import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {TAX_TYPE, TAX_TYPE_API_URL, GET_ALL_TAX_TYPE_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  TaxTypeErrors       : {},
  permission: permission?.tax_type ?? [],
  TaxTypeShowMessage  : null,
  TaxTypeResult       : [],
  TaxTypeButtonSpinner : false,
  TaxTypeTableLoading : true,
  TaxTypeAddDrawer    : false,
  TaxTypeEditData     : [],
  TaxTypeButtonAndModelLabel  : setLocale('taxtype.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewTaxTypeData: [],
    ViewTaxTypeLoader: true,
    DrawerStatus: 0,
};

export const createTaxType = createAsyncThunk(
    "createTaxType",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, TAX_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getTaxType = createAsyncThunk(
    "getTaxType",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_TAX_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewTaxType = createAsyncThunk(
  "viewTaxType",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, TAX_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteTaxType = createAsyncThunk(
  "deleteTaxType",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, TAX_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageTaxTypeSlice = createSlice({
  name: TAX_TYPE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.TaxTypeErrors = {};
    },
    TaxTypeAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.TaxTypeButtonSpinner = false;
      }
      state.TaxTypeAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.TaxTypeEditData    = [];
      state.TaxTypeButtonAndModelLabel = setLocale('taxtype.add');
    },
    TaxTypeEditWithDrawerStatus: (state, action) => {
      state.TaxTypeAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.TaxTypeEditData = action.payload.data;
      state.TaxTypeButtonAndModelLabel = setLocale('taxtype.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTaxType.pending, (state, action) => {
        state.DrawerStatus = 0
        state.TaxTypeButtonSpinner = true;
      }).addCase(createTaxType.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.TaxTypeButtonSpinner = false;
        state.TaxTypeErrors = {}
      }).addCase(createTaxType.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.TaxTypeShowMessage = true;
        state.TaxTypeButtonSpinner = false;
        state.TaxTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getTaxType.pending, (state, action) => {
        state.TaxTypeButtonSpinner = true;
        state.TaxTypeTableLoading = true;
      }).addCase(getTaxType.fulfilled, (state, action) => {
        state.TaxTypeButtonSpinner = false;
        state.TaxTypeTableLoading = false;
        state.TaxTypeResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getTaxType.rejected, (state, action) => {
        state.TaxTypeShowMessage = true; // Set the showMessage flag to display the errors
        state.TaxTypeButtonSpinner = false;
        state.TaxTypeTableLoading = false;
        state.TaxTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewTaxType.pending, (state, action) => {
        state.ViewTaxTypeLoader = true;
        state.ViewTaxTypeData = [];
      }).addCase(viewTaxType.fulfilled, (state, action) => {
        state.ViewTaxTypeLoader = false;
        state.ViewTaxTypeData = action.payload;
      }).addCase(viewTaxType.rejected, (state, action) => {
        state.ViewTaxTypeLoader = false;
        state.ViewTaxTypeData = [];
      })
      .addCase(deleteTaxType.pending, (state, action) => {
        state.TaxTypeTableLoading = true;
      }).addCase(deleteTaxType.fulfilled, (state, action) => {
        state.TaxTypeTableLoading = false;
      }).addCase(deleteTaxType.rejected, (state, action) => {
        state.TaxTypeTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, TaxTypeAddDrawerStatus, TaxTypeEditWithDrawerStatus, updateSortFilters } = manageTaxTypeSlice.actions;

export default manageTaxTypeSlice.reducer;
