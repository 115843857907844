import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {MANAGE_BUSES, MANAGE_BUSES_API_URL, GET_ALL_MANAGE_BUSES_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ManageBusesErrors       : {},
  permission: permission?.manage_buses ?? [],
  ManageBusesShowMessage  : null,
  ManageBusesResult       : [],
  ManageBusesButtonSpinner : false,
  ManageBusesTableLoading : true,
  ManageBusesAddDrawer    : false,
  ManageBusesEditData     : [],
  ManageBusesButtonAndModelLabel  : setLocale('bus.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewManageBusesData: [],
    ViewManageBusesLoader: true,
     DrawerStatus: 0,
};

export const createManageBuses = createAsyncThunk(
    "createManageBuses",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, MANAGE_BUSES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getManageBuses = createAsyncThunk(
    "getManageBuses",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_MANAGE_BUSES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewManageBuses = createAsyncThunk(
  "viewManageBuses",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, MANAGE_BUSES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteManageBuses = createAsyncThunk(
  "deleteManageBuses",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, MANAGE_BUSES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageManageBusesSlice = createSlice({
  name: MANAGE_BUSES,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ManageBusesErrors = {};
    },
    ManageBusesAddDrawerStatus: (state, action) => {
      // state.ManageBusesErrors      = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageBusesAddDrawer   = action.payload.status;
      state.ManageBusesEditData    = [];
      state.ManageBusesButtonAndModelLabel = setLocale('bus.add');
    },
    ManageBusesEditWithDrawerStatus: (state, action) => {
      // state.ManageBusesErrors = {};
      state.ManageBusesAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ManageBusesEditData = action.payload.data;
      state.ManageBusesButtonAndModelLabel = setLocale('bus.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createManageBuses.pending, (state, action) => {
         state.DrawerStatus = 0
        state.ManageBusesButtonSpinner = true;
      }).addCase(createManageBuses.fulfilled, (state, action) => {
         state.DrawerStatus = 0
        state.ManageBusesButtonSpinner = false;
        state.ManageBusesErrors = {}
      }).addCase(createManageBuses.rejected, (state, action) => {
         state.DrawerStatus = 1
        state.ManageBusesShowMessage = true;
        state.ManageBusesButtonSpinner = false;
        state.ManageBusesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getManageBuses.pending, (state, action) => {
        state.ManageBusesButtonSpinner = true;
        state.ManageBusesTableLoading = true;
      }).addCase(getManageBuses.fulfilled, (state, action) => {
        state.ManageBusesButtonSpinner = false;
        state.ManageBusesTableLoading = false;
        state.ManageBusesResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getManageBuses.rejected, (state, action) => {
        state.ManageBusesShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageBusesButtonSpinner = false;
        state.ManageBusesTableLoading = false;
        state.ManageBusesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewManageBuses.pending, (state, action) => {
        state.ViewManageBusesLoader = true;
        state.ViewManageBusesData = [];
      }).addCase(viewManageBuses.fulfilled, (state, action) => {
        state.ViewManageBusesLoader = false;
        state.ViewManageBusesData = action.payload;
      }).addCase(viewManageBuses.rejected, (state, action) => {
        state.ViewManageBusesLoader = false;
        state.ViewManageBusesData = [];
      })
      .addCase(deleteManageBuses.pending, (state, action) => {

      }).addCase(deleteManageBuses.fulfilled, (state, action) => {

      }).addCase(deleteManageBuses.rejected, (state, action) => {

      });
  },
});

export const {onCloseError,setColumnSearch, ManageBusesAddDrawerStatus, ManageBusesEditWithDrawerStatus, updateSortFilters } = manageManageBusesSlice.actions;

export default manageManageBusesSlice.reducer;
