import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {MEDICAL_CONDITION_TYPE, MEDICAL_CONDITION_TYPE_API_URL, GET_ALL_MEDICAL_CONDITION_TYPE_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  MedicalConditionTypeErrors       : {},
  permission: permission?.medical_condition_type ?? [],
  MedicalConditionTypeShowMessage  : null,
  MedicalConditionTypeResult       : [],
  MedicalConditionTypeButtonSpinner : false,
  MedicalConditionTypeTableLoading : true,
  MedicalConditionTypeAddDrawer    : false,
  MedicalConditionTypeEditData     : [],
  MedicalConditionTypeButtonAndModelLabel  : setLocale('medical_condition_type.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewMedicalConditionTypeData: [],
    ViewMedicalConditionTypeLoader: true,
    DrawerStatus: 0,
};

export const createMedicalConditionType = createAsyncThunk(
    "createMedicalConditionType",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, MEDICAL_CONDITION_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getMedicalConditionType = createAsyncThunk(
    "getMedicalConditionType",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_MEDICAL_CONDITION_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewMedicalConditionType = createAsyncThunk(
  "viewMedicalConditionType",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, MEDICAL_CONDITION_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteMedicalConditionType = createAsyncThunk(
  "deleteMedicalConditionType",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, MEDICAL_CONDITION_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageMedicalConditionTypeSlice = createSlice({
  name: MEDICAL_CONDITION_TYPE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.MedicalConditionTypeErrors = {};
    },
    MedicalConditionTypeAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.MedicalConditionTypeButtonSpinner = false;
      }
      // state.MedicalConditionTypeErrors      = {};
      state.MedicalConditionTypeAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.MedicalConditionTypeEditData    = [];
      state.MedicalConditionTypeButtonAndModelLabel = setLocale('medical_condition_type.add');
    },
    MedicalConditionTypeEditWithDrawerStatus: (state, action) => {
      // state.MedicalConditionTypeErrors = {}; 
      state.MedicalConditionTypeAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.MedicalConditionTypeEditData = action.payload.data;
      state.MedicalConditionTypeButtonAndModelLabel = setLocale('medical_condition_type.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMedicalConditionType.pending, (state, action) => {
         state.DrawerStatus = 0
        state.MedicalConditionTypeButtonSpinner = true;
      }).addCase(createMedicalConditionType.fulfilled, (state, action) => {
         state.DrawerStatus = 0
        state.MedicalConditionTypeButtonSpinner = false;
        state.MedicalConditionTypeErrors = {}
      }).addCase(createMedicalConditionType.rejected, (state, action) => {
         state.DrawerStatus =  1
        state.MedicalConditionTypeShowMessage = true;
        state.MedicalConditionTypeButtonSpinner = false;
        state.MedicalConditionTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getMedicalConditionType.pending, (state, action) => {
        state.MedicalConditionTypeButtonSpinner = true;
        state.MedicalConditionTypeTableLoading = true;
      }).addCase(getMedicalConditionType.fulfilled, (state, action) => {
        state.MedicalConditionTypeButtonSpinner = false;
        state.MedicalConditionTypeTableLoading = false;
        state.MedicalConditionTypeResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.total,
            current: action.payload.current_page,
            pageSize: action.payload.per_page
        };
      }).addCase(getMedicalConditionType.rejected, (state, action) => {
        state.MedicalConditionTypeShowMessage = true; // Set the showMessage flag to display the errors
        state.MedicalConditionTypeButtonSpinner = false;
        state.MedicalConditionTypeTableLoading = false;
        state.MedicalConditionTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewMedicalConditionType.pending, (state, action) => {
        state.ViewMedicalConditionTypeLoader = true;
        state.ViewMedicalConditionTypeData = [];
      }).addCase(viewMedicalConditionType.fulfilled, (state, action) => {
        state.ViewMedicalConditionTypeLoader = false;
        state.ViewMedicalConditionTypeData = action.payload;
      }).addCase(viewMedicalConditionType.rejected, (state, action) => {
        state.ViewMedicalConditionTypeLoader = false;
        state.ViewMedicalConditionTypeData = [];
      })
      .addCase(deleteMedicalConditionType.pending, (state, action) => {

      }).addCase(deleteMedicalConditionType.fulfilled, (state, action) => {

      }).addCase(deleteMedicalConditionType.rejected, (state, action) => {

      });
  },
});

export const {onCloseError,setColumnSearch, MedicalConditionTypeAddDrawerStatus, MedicalConditionTypeEditWithDrawerStatus, updateSortFilters } = manageMedicalConditionTypeSlice.actions;

export default manageMedicalConditionTypeSlice.reducer;
