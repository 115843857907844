import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { NAME_OF_SEARCH_SLICE } from 'constants/AppConstants';
import Common from 'services/CommonService/Common';

export const initialState = {
  SearchErrors: {},
  SearchShowMessage: 'No Search Result',
  searchValue: '',
  SearchResult: [],
  SearchLoader: false,
};

export const createAllSearch = createAsyncThunk(
  'createAllSearch',
  async (data) => {
    try {
      const response = await Common.getAll(data, "all-search");
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);



export const manageSearchSlice = createSlice({
  name: NAME_OF_SEARCH_SLICE,
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    clearSearchResults: (state, action) => {
      state.SearchResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAllSearch.pending, (state, action) => {
        state.SearchLoader = true;
      }).addCase(createAllSearch.fulfilled, (state, action) => {
        state.SearchLoader = false;
        state.SearchErrors = {}
        console.log(state.searchValue);
        const filteredResults = action?.payload?.filter((item) => {
          if (state.searchValue) {
            // Check if the title or any tab content includes the search state.searchValue
            const titleMatch = item?.title && item.title.toLowerCase().includes(state.searchValue.toLowerCase());

            // Check if item.tabs is an array before using the some method
            const tabContentMatch = Array.isArray(item?.tabs) && item.tabs.some((tab) =>
              tab.name && tab.name.toLowerCase().includes(state.searchValue.toLowerCase()) ||
              tab.content && tab.content.toLowerCase().includes(state.searchValue.toLowerCase())
            );

            return titleMatch || tabContentMatch;
          }
        });
        state.SearchResult = filteredResults;
      })
      // .addCase(createAllSearch.rejected, (state, action) => {
      //   state.SearchErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      //   state.SearchLoader = false;
      // });
      .addCase(createAllSearch.rejected, (state, action) => {
        try {
          // Try to parse the error message as JSON
          state.SearchErrors = JSON.parse(action.error.message);
        } catch (parseError) {
          // If parsing fails, set a default error message or handle it as needed
          console.error("Error parsing JSON:", parseError);
          state.SearchErrors = { general: "An error occurred" };
        }

        state.SearchLoader = false;
      });
  },
});

export const { setSearchValue, clearSearchResults } = manageSearchSlice.actions;

export default manageSearchSlice.reducer;
