import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {THEME_SETTING, THEME_SETTING_API_URL, GET_ALL_THEME_SETTING_API_URL} from 'constants/AppConstants';
import { USER_INFORMATION,USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
import { THEME_CONFIG } from 'configs/AppConfig'

const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));
const userTheme = JSON.parse(localStorage.getItem(USER_INFORMATION));
export const initialState = THEME_CONFIG

export const updateThemeSetting = createAsyncThunk('updateThemeSetting', async (data) => {  
  try {
    const response = await CommonService.postData(data, THEME_SETTING_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
})


export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		toggleCollapsedNav: (state, action) => {
			state.navCollapsed = action.payload
			if (userTheme) {
				userTheme.theme_setting.nav_collapsed = action.payload
				localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			}
		},
		onNavStyleChange: (state, action) => {
			state.sideNavTheme = action.payload
			if (userTheme) {
				userTheme.theme_setting.side_nav_theme = action.payload
				localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			}
		},
		onLocaleChange: (state, action) => {
			state.locale = action.payload
			if (userTheme) {
				userTheme.theme_setting.locale = action.payload
				localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			}
		},
		onNavTypeChange: (state, action) => {
			state.navType = action.payload
			if (userTheme) {
				userTheme.theme_setting.nav_type = action.payload
				localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			}
		},
		onTopNavColorChange: (state, action) => {
			state.topNavColor = action.payload
			if (userTheme) {
				userTheme.theme_setting.top_nav_color = action.payload
				localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			}
		},
		onHeaderNavColorChange: (state, action) => {
			state.headerNavColor = action.payload
			if (userTheme) {
				userTheme.theme_setting.header_nav_color = action.payload
				localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			}
		},
		onMobileNavToggle: (state, action) => {
			state.mobileNav = action.payload
			if (userTheme) {
				userTheme.theme_setting.mobile_nav = action.payload
				localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			}
		},
		onSwitchTheme: (state, action) => {
			state.currentTheme = action.payload
			if (userTheme) {
				userTheme.theme_setting.current_theme = action.payload
				localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			}
		},
		onDirectionChange: (state, action) => {
			state.direction = action.payload
			if (userTheme) {
				userTheme.theme_setting.direction = action.payload
				localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			}
		},
		onBlankLayout: (state, action) => {
			state.blankLayout = action.payload
			// if (userTheme) {
			// 	userTheme.theme_setting.blank_layout = action.payload
			// 	localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			// }
		},
	},
})

export const { 
	toggleCollapsedNav,
	onNavStyleChange,
	onLocaleChange,
	onNavTypeChange,
	onTopNavColorChange,
	onHeaderNavColorChange,
	onMobileNavToggle,
	onSwitchTheme,
	onDirectionChange,
	onBlankLayout
} = themeSlice.actions

export default themeSlice.reducer