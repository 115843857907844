import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {REPORT_CARD, REPORT_CARD_API_URL, GET_ALL_REPORT_CARD_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ReportCardErrors       : {},
  permission: permission?.report_card ?? [],
  ReportCardShowMessage  : null,
  ReportCardResult       : [],
  ReportCardButtonSpinner : false,
  ReportCardTableLoading : true,
  ReportCardAddDrawer    : false,
  ReportCardEditData     : [],
  ReportCardButtonAndModelLabel  : setLocale('reportcard.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewReportCardData: [],
    ViewReportCardLoader: true,
    DrawerStatus: 0,
};

export const createReportCard = createAsyncThunk(
    "createReportCard",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, REPORT_CARD_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getReportCard = createAsyncThunk(
    "getReportCard",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_REPORT_CARD_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewReportCard = createAsyncThunk(
  "viewReportCard",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, REPORT_CARD_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteReportCard = createAsyncThunk(
  "deleteReportCard",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, REPORT_CARD_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageReportCardSlice = createSlice({
  name: REPORT_CARD,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ReportCardErrors = {};
    },
    ReportCardAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.ReportCardButtonSpinner = false;
      }
      state.ReportCardAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ReportCardEditData    = [];
      state.ReportCardButtonAndModelLabel = setLocale('reportcard.add');
    },
    ReportCardEditWithDrawerStatus: (state, action) => {
      state.ReportCardAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ReportCardEditData = action.payload.data;
      state.ReportCardButtonAndModelLabel = setLocale('reportcard.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createReportCard.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ReportCardButtonSpinner = true;
      }).addCase(createReportCard.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ReportCardButtonSpinner = false;
        state.ReportCardErrors = {}
      }).addCase(createReportCard.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ReportCardShowMessage = true;
        state.ReportCardButtonSpinner = false;
        state.ReportCardErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getReportCard.pending, (state, action) => {
        state.ReportCardButtonSpinner = true;
        state.ReportCardTableLoading = true;
      }).addCase(getReportCard.fulfilled, (state, action) => {
        state.ReportCardButtonSpinner = false;
        state.ReportCardTableLoading = false;
        state.ReportCardResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getReportCard.rejected, (state, action) => {
        state.ReportCardShowMessage = true; // Set the showMessage flag to display the errors
        state.ReportCardButtonSpinner = false;
        state.ReportCardTableLoading = false;
        state.ReportCardErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewReportCard.pending, (state, action) => {
        state.ViewReportCardLoader = true;
        state.ViewReportCardData = [];
      }).addCase(viewReportCard.fulfilled, (state, action) => {
        state.ViewReportCardLoader = false;
        state.ViewReportCardData = action.payload;
      }).addCase(viewReportCard.rejected, (state, action) => {
        state.ViewReportCardLoader = false;
        state.ViewReportCardData = [];
      })
      .addCase(deleteReportCard.pending, (state, action) => {
        state.ReportCardTableLoading = true;
      }).addCase(deleteReportCard.fulfilled, (state, action) => {
        state.ReportCardTableLoading = false;
      }).addCase(deleteReportCard.rejected, (state, action) => {
        state.ReportCardTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, ReportCardAddDrawerStatus, ReportCardEditWithDrawerStatus, updateSortFilters } = manageReportCardSlice.actions;

export default manageReportCardSlice.reducer;
