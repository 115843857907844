import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RECURRING_CHARGES, RECURRING_CHARGES_API_URL, GET_ALL_RECURRING_CHARGES_API_URL, GET_ALL_GRADE_LEVEL_STUDENT_API_URL, APPLY_SIBLING_DISCOUNT_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  RecurringChargesErrors: {},
  permission: permission?.recurring_charges ?? [],
  RecurringChargesShowMessage: null,
  RecurringChargesResult: [],
  GradeLevelStudentResult: [],
  RecurringChargesButtonSpinner: false,
  RecurringChargesTableLoading: true,
  RecurringChargesAddDrawer: false,
  RecurringChargesEditData: [],
  RecurringChargesButtonAndModelLabel: setLocale('charges_and_invoices.add_recurring_charges'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewRecurringChargesData: [],
  selectedRowKeys: [],
  ViewRecurringChargesLoader: true,
  StudentButtonSpinner: false,
  siblingDiscountSpinner: false,
  DrawerStatus: 0,
};

export const createRecurringCharges = createAsyncThunk(
  "createRecurringCharges",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, RECURRING_CHARGES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getRecurringCharges = createAsyncThunk(
  "getRecurringCharges",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_RECURRING_CHARGES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewRecurringCharges = createAsyncThunk(
  "viewRecurringCharges",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, RECURRING_CHARGES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteRecurringCharges = createAsyncThunk(
  "deleteRecurringCharges",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, RECURRING_CHARGES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getGradeLevelStudent = createAsyncThunk(
  "getGradeLevelStudent",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_GRADE_LEVEL_STUDENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const applySiblingDiscountAction = createAsyncThunk(
  "applySiblingDiscountAction",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, APPLY_SIBLING_DISCOUNT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageRecurringChargesSlice = createSlice({
  name: RECURRING_CHARGES,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.RecurringChargesErrors = {};
    },
    RecurringChargesAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.RecurringChargesButtonSpinner = false;
      }
      state.selectedRowKeys = [];
      // state.RecurringChargesErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.RecurringChargesAddDrawer = action.payload.status;
      state.RecurringChargesEditData = [];
      state.RecurringChargesButtonAndModelLabel = setLocale('charges_and_invoices.add_recurring_charges');
    },
    RecurringChargesEditWithDrawerStatus: (state, action) => {
      // state.RecurringChargesErrors = {};
      state.RecurringChargesAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.RecurringChargesEditData = action.payload.data;
      state.RecurringChargesButtonAndModelLabel = setLocale('charges_and_invoices.edit_recurring_charges');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload.selected;
    },
    resetGradeLevelStudentResult: (state, action) => {
      state.GradeLevelStudentResult = action.payload.selected;
      state.selectedRowKeys = action.payload.selected;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRecurringCharges.pending, (state, action) => {
        state.DrawerStatus = 0
        state.RecurringChargesButtonSpinner = true;
      }).addCase(createRecurringCharges.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.RecurringChargesButtonSpinner = false;
        state.RecurringChargesErrors = {}
      }).addCase(createRecurringCharges.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.RecurringChargesShowMessage = true;
        state.RecurringChargesButtonSpinner = false;
        state.RecurringChargesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getRecurringCharges.pending, (state, action) => {
        state.RecurringChargesButtonSpinner = true;
        state.RecurringChargesTableLoading = true;
      })
      .addCase(getRecurringCharges.fulfilled, (state, action) => {
        state.RecurringChargesButtonSpinner = false;
        state.RecurringChargesTableLoading = false;
        state.RecurringChargesResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(getRecurringCharges.rejected, (state, action) => {
        state.RecurringChargesShowMessage = true; // Set the showMessage flag to display the errors
        state.RecurringChargesButtonSpinner = false;
        state.RecurringChargesTableLoading = false;
        state.RecurringChargesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getGradeLevelStudent.pending, (state, action) => {
        state.StudentButtonSpinner = true;
      })
      .addCase(getGradeLevelStudent.fulfilled, (state, action) => {
        state.GradeLevelStudentResult = action.payload;
        state.StudentButtonSpinner = false;
      })
      .addCase(getGradeLevelStudent.rejected, (state, action) => {
        state.StudentButtonSpinner = false;
      })

      .addCase(applySiblingDiscountAction.pending, (state, action) => {
        state.siblingDiscountSpinner = true;
      })
      .addCase(applySiblingDiscountAction.fulfilled, (state, action) => {
        state.GradeLevelStudentResult = action.payload;
        state.siblingDiscountSpinner = false;
      })
      .addCase(applySiblingDiscountAction.rejected, (state, action) => {
        state.siblingDiscountSpinner = false;
      })

      .addCase(viewRecurringCharges.pending, (state, action) => {
        state.ViewRecurringChargesLoader = true;
        state.ViewRecurringChargesData = [];
      }).addCase(viewRecurringCharges.fulfilled, (state, action) => {
        state.ViewRecurringChargesLoader = false;
        state.ViewRecurringChargesData = action.payload;
      }).addCase(viewRecurringCharges.rejected, (state, action) => {
        state.ViewRecurringChargesLoader = false;
        state.ViewRecurringChargesData = [];
      })
      .addCase(deleteRecurringCharges.pending, (state, action) => {

      }).addCase(deleteRecurringCharges.fulfilled, (state, action) => {

      }).addCase(deleteRecurringCharges.rejected, (state, action) => {

      });
  },
});

export const { onCloseError, setColumnSearch, resetGradeLevelStudentResult, setSelectedRowKeys, RecurringChargesAddDrawerStatus, RecurringChargesEditWithDrawerStatus, updateSortFilters } = manageRecurringChargesSlice.actions;

export default manageRecurringChargesSlice.reducer;
