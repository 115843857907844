import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {LEGAL_STATUS, LEGAL_STATUS_API_URL, GET_ALL_LEGAL_STATUS_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  LegalStatusErrors       : {},
  permission: permission?.legal_status ?? [],
  LegalStatusShowMessage  : null,
  LegalStatusResult       : [],
  LegalStatusButtonSpinner : false,
  LegalStatusTableLoading : true,
  LegalStatusAddDrawer    : false,
  LegalStatusEditData     : [],
  LegalStatusButtonAndModelLabel  : setLocale('legalstatus.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewLegalStatusData: [],
    ViewLegalStatusLoader: true,
    DrawerStatus: 0,
};

export const createLegalStatus = createAsyncThunk(
    "createLegalStatus",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, LEGAL_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getLegalStatus = createAsyncThunk(
    "getLegalStatus",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_LEGAL_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewLegalStatus = createAsyncThunk(
  "viewLegalStatus",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, LEGAL_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteLegalStatus = createAsyncThunk(
  "deleteLegalStatus",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, LEGAL_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageLegalStatusSlice = createSlice({
  name: LEGAL_STATUS,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.LegalStatusErrors = {};
    },
    LegalStatusAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.LegalStatusButtonSpinner = false;
      }
      state.LegalStatusAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.LegalStatusEditData    = [];
      state.LegalStatusButtonAndModelLabel = setLocale('legalstatus.add');
    },
    LegalStatusEditWithDrawerStatus: (state, action) => {
      state.LegalStatusAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.LegalStatusEditData = action.payload.data;
      state.LegalStatusButtonAndModelLabel = setLocale('legalstatus.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLegalStatus.pending, (state, action) => {
        state.DrawerStatus = 0
        state.LegalStatusButtonSpinner = true;
      }).addCase(createLegalStatus.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.LegalStatusButtonSpinner = false;
        state.LegalStatusErrors = {}
      }).addCase(createLegalStatus.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.LegalStatusShowMessage = true;
        state.LegalStatusButtonSpinner = false;
        state.LegalStatusErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getLegalStatus.pending, (state, action) => {
        state.LegalStatusButtonSpinner = true;
        state.LegalStatusTableLoading = true;
      }).addCase(getLegalStatus.fulfilled, (state, action) => {
        state.LegalStatusButtonSpinner = false;
        state.LegalStatusTableLoading = false;
        state.LegalStatusResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getLegalStatus.rejected, (state, action) => {
        state.LegalStatusShowMessage = true; // Set the showMessage flag to display the errors
        state.LegalStatusButtonSpinner = false;
        state.LegalStatusTableLoading = false;
        state.LegalStatusErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewLegalStatus.pending, (state, action) => {
        state.ViewLegalStatusLoader = true;
        state.ViewLegalStatusData = [];
      }).addCase(viewLegalStatus.fulfilled, (state, action) => {
        state.ViewLegalStatusLoader = false;
        state.ViewLegalStatusData = action.payload;
      }).addCase(viewLegalStatus.rejected, (state, action) => {
        state.ViewLegalStatusLoader = false;
        state.ViewLegalStatusData = [];
      })
      .addCase(deleteLegalStatus.pending, (state, action) => {
        state.LegalStatusTableLoading = true;
      }).addCase(deleteLegalStatus.fulfilled, (state, action) => {
        state.LegalStatusTableLoading = false;
      }).addCase(deleteLegalStatus.rejected, (state, action) => {
        state.LegalStatusTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, LegalStatusAddDrawerStatus, LegalStatusEditWithDrawerStatus, updateSortFilters } = manageLegalStatusSlice.actions;

export default manageLegalStatusSlice.reducer;
