import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { INCIDENT_TYPE, INCIDENT_TYPE_API_URL, GET_ALL_INCIDENT_TYPE_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  IncidentTypeErrors: {},
  permission: permission?.incident_type ?? [],
  IncidentTypeShowMessage: null,
  IncidentTypeResult: [],
  IncidentTypeButtonSpinner: false,
  IncidentTypeTableLoading: true,
  IncidentTypeAddDrawer: false,
  IncidentTypeEditData: [],
  IncidentTypeButtonAndModelLabel: setLocale('incidenttype.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewIncidentTypeData: [],
  ViewIncidentTypeLoader: true,
};

export const createIncidentType = createAsyncThunk(
  "createIncidentType",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, INCIDENT_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getIncidentType = createAsyncThunk(
  "getIncidentType",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_INCIDENT_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewIncidentType = createAsyncThunk(
  "viewIncidentType",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, INCIDENT_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteIncidentType = createAsyncThunk(
  "deleteIncidentType",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, INCIDENT_TYPE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageIncidentTypeSlice = createSlice({
  name: INCIDENT_TYPE,
  initialState,
  reducers: {
    IncidentTypeAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.IncidentTypeButtonSpinner = false;
      }
      state.IncidentTypeErrors = {};
      state.IncidentTypeAddDrawer = action.payload;
      state.IncidentTypeEditData = [];
      state.IncidentTypeButtonAndModelLabel = setLocale('incidenttype.add');
    },
    IncidentTypeEditWithDrawerStatus: (state, action) => {
      state.IncidentTypeErrors = {};
      state.IncidentTypeAddDrawer = true;
      state.IncidentTypeEditData = action.payload;
      state.IncidentTypeButtonAndModelLabel = setLocale('incidenttype.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createIncidentType.pending, (state, action) => {
        state.IncidentTypeButtonSpinner = true;
      }).addCase(createIncidentType.fulfilled, (state, action) => {
        state.IncidentTypeButtonSpinner = false;
        state.IncidentTypeErrors = {}
      }).addCase(createIncidentType.rejected, (state, action) => {
        state.IncidentTypeShowMessage = true;
        state.IncidentTypeButtonSpinner = false;
        state.IncidentTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getIncidentType.pending, (state, action) => {
        state.IncidentTypeButtonSpinner = true;
        state.IncidentTypeTableLoading = true;
      }).addCase(getIncidentType.fulfilled, (state, action) => {
        state.IncidentTypeButtonSpinner = false;
        state.IncidentTypeTableLoading = false;
        state.IncidentTypeResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getIncidentType.rejected, (state, action) => {
        state.IncidentTypeShowMessage = true; // Set the showMessage flag to display the errors
        state.IncidentTypeButtonSpinner = false;
        state.IncidentTypeTableLoading = false;
        state.IncidentTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewIncidentType.pending, (state, action) => {
        state.ViewIncidentTypeLoader = true;
        state.ViewIncidentTypeData = [];
      }).addCase(viewIncidentType.fulfilled, (state, action) => {
        state.ViewIncidentTypeLoader = false;
        state.ViewIncidentTypeData = action.payload;
      }).addCase(viewIncidentType.rejected, (state, action) => {
        state.ViewIncidentTypeLoader = false;
        state.ViewIncidentTypeData = [];
      })
      .addCase(deleteIncidentType.pending, (state, action) => {

      }).addCase(deleteIncidentType.fulfilled, (state, action) => {

      }).addCase(deleteIncidentType.rejected, (state, action) => {

      });
  },
});

export const { setColumnSearch, IncidentTypeAddDrawerStatus, IncidentTypeEditWithDrawerStatus, updateSortFilters } = manageIncidentTypeSlice.actions;

export default manageIncidentTypeSlice.reducer;
