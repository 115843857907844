import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {ASSESSMENT_LEVEL, ASSESSMENT_LEVEL_API_URL, GET_ALL_ASSESSMENT_LEVEL_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  AssessmentLevelErrors       : {},
  permission: permission?.assessment_level ?? [],
  AssessmentLevelShowMessage  : null,
  AssessmentLevelResult       : [],
  AssessmentLevelButtonSpinner : false,
  AssessmentLevelTableLoading : true,
  AssessmentLevelAddDrawer    : false,
  AssessmentLevelEditData     : [],
  AssessmentLevelButtonAndModelLabel  : setLocale('assessmentlevel.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewAssessmentLevelData: [],
    ViewAssessmentLevelLoader: true,
    DrawerStatus: 0,
};

export const createAssessmentLevel = createAsyncThunk(
    "createAssessmentLevel",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, ASSESSMENT_LEVEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getAssessmentLevel = createAsyncThunk(
    "getAssessmentLevel",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_ASSESSMENT_LEVEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewAssessmentLevel = createAsyncThunk(
  "viewAssessmentLevel",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, ASSESSMENT_LEVEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteAssessmentLevel = createAsyncThunk(
  "deleteAssessmentLevel",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ASSESSMENT_LEVEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageAssessmentLevelSlice = createSlice({
  name: ASSESSMENT_LEVEL,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.AssessmentLevelErrors = {};
    },
    AssessmentLevelAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.AssessmentLevelButtonSpinner = false;
      }
      state.AssessmentLevelAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.AssessmentLevelEditData    = [];
      state.AssessmentLevelButtonAndModelLabel = setLocale('assessmentlevel.add');
    },
    AssessmentLevelEditWithDrawerStatus: (state, action) => {
      state.AssessmentLevelAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.AssessmentLevelEditData = action.payload.data;
      state.AssessmentLevelButtonAndModelLabel = setLocale('assessmentlevel.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAssessmentLevel.pending, (state, action) => {
        state.DrawerStatus = 0
        state.AssessmentLevelButtonSpinner = true;
      }).addCase(createAssessmentLevel.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.AssessmentLevelButtonSpinner = false;
        state.AssessmentLevelErrors = {}
      }).addCase(createAssessmentLevel.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.AssessmentLevelShowMessage = true;
        state.AssessmentLevelButtonSpinner = false;
        state.AssessmentLevelErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getAssessmentLevel.pending, (state, action) => {
        state.AssessmentLevelButtonSpinner = true;
        state.AssessmentLevelTableLoading = true;
      }).addCase(getAssessmentLevel.fulfilled, (state, action) => {
        state.AssessmentLevelButtonSpinner = false;
        state.AssessmentLevelTableLoading = false;
        state.AssessmentLevelResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getAssessmentLevel.rejected, (state, action) => {
        state.AssessmentLevelShowMessage = true; // Set the showMessage flag to display the errors
        state.AssessmentLevelButtonSpinner = false;
        state.AssessmentLevelTableLoading = false;
        state.AssessmentLevelErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewAssessmentLevel.pending, (state, action) => {
        state.ViewAssessmentLevelLoader = true;
        state.ViewAssessmentLevelData = [];
      }).addCase(viewAssessmentLevel.fulfilled, (state, action) => {
        state.ViewAssessmentLevelLoader = false;
        state.ViewAssessmentLevelData = action.payload;
      }).addCase(viewAssessmentLevel.rejected, (state, action) => {
        state.ViewAssessmentLevelLoader = false;
        state.ViewAssessmentLevelData = [];
      })
      .addCase(deleteAssessmentLevel.pending, (state, action) => {
        state.AssessmentLevelTableLoading = true;
      }).addCase(deleteAssessmentLevel.fulfilled, (state, action) => {
        state.AssessmentLevelTableLoading = false;
      }).addCase(deleteAssessmentLevel.rejected, (state, action) => {
        state.AssessmentLevelTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, AssessmentLevelAddDrawerStatus, AssessmentLevelEditWithDrawerStatus, updateSortFilters } = manageAssessmentLevelSlice.actions;

export default manageAssessmentLevelSlice.reducer;
