import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CALENDAR_CATEGORY, CALENDAR_CATEGORY_API_URL, GET_ALL_CALENDAR_CATEGORY_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  CalendarCategoryErrors: {},
  permission: permission?.calendar_category ?? [],
  CalendarCategoryShowMessage: null,
  CalendarCategoryResult: [],
  CalendarCategoryButtonSpinner: false,
  CalendarCategoryTableLoading: true,
  CalendarCategoryAddDrawer: false,
  CalendarCategoryEditData: [],
  CalendarCategoryButtonAndModelLabel: setLocale('calendarcategory.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewCalendarCategoryData: [],
  ViewCalendarCategoryLoader: true,
  DrawerStatus: 0,
};

export const createCalendarCategory = createAsyncThunk(
  "createCalendarCategory",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, CALENDAR_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCalendarCategory = createAsyncThunk(
  "getCalendarCategory",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_CALENDAR_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewCalendarCategory = createAsyncThunk(
  "viewCalendarCategory",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, CALENDAR_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteCalendarCategory = createAsyncThunk(
  "deleteCalendarCategory",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CALENDAR_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageCalendarCategorySlice = createSlice({
  name: CALENDAR_CATEGORY,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.CalendarCategoryErrors = {};
    },
    CalendarCategoryAddDrawerStatus: (state, action) => {
      // state.CalendarCategoryErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.CalendarCategoryAddDrawer = action.payload.status;
      state.CalendarCategoryEditData = [];
      state.CalendarCategoryButtonAndModelLabel = setLocale('calendarcategory.add');
    },
    CalendarCategoryEditWithDrawerStatus: (state, action) => {
      // state.CalendarCategoryErrors = {};
      state.CalendarCategoryAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.CalendarCategoryEditData = action.payload.data;
      state.CalendarCategoryButtonAndModelLabel = setLocale('calendarcategory.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCalendarCategory.pending, (state, action) => {
        state.DrawerStatus = 0
        state.CalendarCategoryButtonSpinner = true;
      }).addCase(createCalendarCategory.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.CalendarCategoryButtonSpinner = false;
        state.CalendarCategoryErrors = {}
      }).addCase(createCalendarCategory.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.CalendarCategoryShowMessage = true;
        state.CalendarCategoryButtonSpinner = false;
        state.CalendarCategoryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getCalendarCategory.pending, (state, action) => {
        state.CalendarCategoryButtonSpinner = true;
        state.CalendarCategoryTableLoading = true;
      }).addCase(getCalendarCategory.fulfilled, (state, action) => {
        state.CalendarCategoryButtonSpinner = false;
        state.CalendarCategoryTableLoading = false;
        state.CalendarCategoryResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page
        };
      }).addCase(getCalendarCategory.rejected, (state, action) => {
        state.CalendarCategoryShowMessage = true; // Set the showMessage flag to display the errors
        state.CalendarCategoryButtonSpinner = false;
        state.CalendarCategoryTableLoading = false;
        state.CalendarCategoryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewCalendarCategory.pending, (state, action) => {
        state.ViewCalendarCategoryLoader = true;
        state.ViewCalendarCategoryData = [];
      }).addCase(viewCalendarCategory.fulfilled, (state, action) => {
        state.ViewCalendarCategoryLoader = false;
        state.ViewCalendarCategoryData = action.payload;
      }).addCase(viewCalendarCategory.rejected, (state, action) => {
        state.ViewCalendarCategoryLoader = false;
        state.ViewCalendarCategoryData = [];
      })
      .addCase(deleteCalendarCategory.pending, (state, action) => {

      }).addCase(deleteCalendarCategory.fulfilled, (state, action) => {

      }).addCase(deleteCalendarCategory.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, CalendarCategoryAddDrawerStatus, CalendarCategoryEditWithDrawerStatus, updateSortFilters } = manageCalendarCategorySlice.actions;

export default manageCalendarCategorySlice.reducer;
