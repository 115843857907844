import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  PURCHASE_REQUESTS, 
  PURCHASE_REQUESTS_API_URL, 
  GET_ALL_PURCHASE_REQUESTS_API_URL, 
  DELETE_PROCUREMENT_ITEM_API_URL,
  UPDATE_PURCHASE_REQUEST,
  UPDATE_PURCHASE_REQUEST_STATUS,
  DELETE_PROCUREMENT_FILE,
  GET_CLASSES_BY_GRADE,
  TEACHER_UPDATE_PURCHASE_REQUEST_STATUS
} from 'constants/purchase-requests/index.js';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  PurchaseRequestsErrors: {},
  permission: permission?.purchase_requests ?? [],
  PurchaseRequestsShowMessage: null,
  PurchaseRequestsResult: [],
  PurchaseRequestsButtonSpinner: false,
  PurchaseRequestsTableLoading: true,
  PurchaseRequestsAddDrawer: false,
  purchaseRequestViewDrawer: false,
  PurchaseRequestsEditData: [],
  PurchaseRequestsButtonAndModelLabel: setLocale('purchaserequests.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewPurchaseRequestsData: [],
  ViewPurchaseRequestsLoader: true,
  DrawerStatus: 0,
  gradeClasses: [],
  gradeClassesLoader: false
};
export const teacherUpdatePurchaseRequestStatus = createAsyncThunk("teacherUpdatePurchaseRequestStatus", async (data) => {
  try {
    return await CommonService.postData(data, TEACHER_UPDATE_PURCHASE_REQUEST_STATUS);      
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});
export const getClassesByGrade = createAsyncThunk("getClassesByGrade", async (data) => {
  try {
    return await CommonService.postData(data, GET_CLASSES_BY_GRADE);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});
export const createPurchaseRequests = createAsyncThunk("createPurchaseRequests", async (data) => {
    try {     
       return await CommonService.postData(data, PURCHASE_REQUESTS_API_URL)    
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
});
export const updatePurchaseRequests = createAsyncThunk("updatePurchaseRequests", async (data) => {
  try {     
     return await CommonService.postData(data, UPDATE_PURCHASE_REQUEST)    
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});

export const getPurchaseRequests = createAsyncThunk("getPurchaseRequests", async (data) => {
    try {
      return await CommonService.getAllPost(data, GET_ALL_PURCHASE_REQUESTS_API_URL);      
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); 
    }
});

export const deleteProcurementItem = createAsyncThunk("deleteProcurementItem", async (data) => {
    try {
      return await CommonService.getAllPost(data, DELETE_PROCUREMENT_ITEM_API_URL);      
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
});

export const viewPurchaseRequests = createAsyncThunk("viewPurchaseRequests", async (id) => {
    try {
      return await CommonService.showOne(id, PURCHASE_REQUESTS_API_URL);      
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
});

export const deletePurchaseRequests = createAsyncThunk("deletePurchaseRequests", async (data) => {
    try {
      return await CommonService.deleteOne(data, PURCHASE_REQUESTS_API_URL);      
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
});

export const updatePurchaseRequestStatus = createAsyncThunk("updatePurchaseRequestStatus", async (data) => {
  try {
    return await CommonService.postData(data, UPDATE_PURCHASE_REQUEST_STATUS);      
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});
export const deleteProcurementFile = createAsyncThunk("deleteProcurementFile", async (data) => {
  try {
    return await CommonService.postData(data, DELETE_PROCUREMENT_FILE);      
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
})
export const managePurchaseRequestsSlice = createSlice({
  name: PURCHASE_REQUESTS,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.RelationshipErrors = {};
    },
    PurchaseRequestsAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.PurchaseRequestsButtonSpinner = false;
      }
      state.PurchaseRequestsAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.PurchaseRequestsEditData = [];
      state.PurchaseRequestsButtonAndModelLabel = setLocale('purchaserequests.add');
    },
    PurchaseRequestsEditWithDrawerStatus: (state, action) => {
      state.PurchaseRequestsAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.PurchaseRequestsEditData = action.payload.data;
      state.PurchaseRequestsButtonAndModelLabel = setLocale('purchaserequests.edit');
    },
    setPurchaseRequestsViewDrawer: (state, action) => {
      state.PurchaseRequestsButtonAndModelLabel = setLocale('purchaserequests.view');
      state.purchaseRequestViewDrawer = action.payload.status;
      state.PurchaseRequestsEditData = action.payload.data
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClassesByGrade.pending, (state, action) => {
        state.gradeClasses = [];
        state.gradeClassesLoader = true;
      }).addCase(getClassesByGrade.fulfilled, (state, action) => {        
        state.gradeClasses = action.payload;
        state.gradeClassesLoader = false;
      }).addCase(getClassesByGrade.rejected, (state, action) => {
        state.gradeClassesLoader = false;
      })
      .addCase(createPurchaseRequests.pending, (state, action) => {        
        state.PurchaseRequestsButtonSpinner = true;
      }).addCase(createPurchaseRequests.fulfilled, (state, action) => {        
        state.PurchaseRequestsButtonSpinner = false;
        state.PurchaseRequestsErrors = {}
      }).addCase(createPurchaseRequests.rejected, (state, action) => {        
        state.PurchaseRequestsShowMessage = true;
        state.PurchaseRequestsButtonSpinner = false;
        state.PurchaseRequestsErrors = JSON.parse(action.error.message); 
      })
      .addCase(updatePurchaseRequestStatus.pending, (state, action) => {
        state.PurchaseRequestsButtonSpinner = true;
      }).addCase(updatePurchaseRequestStatus.fulfilled, (state, action) => {
        state.PurchaseRequestsButtonSpinner = false;
        state.PurchaseRequestsErrors = {}
      }).addCase(updatePurchaseRequestStatus.rejected, (state, action) => {
        state.PurchaseRequestsShowMessage = true;
        state.PurchaseRequestsButtonSpinner = false;
        state.PurchaseRequestsErrors = JSON.parse(action.error.message); 
      })
      .addCase(updatePurchaseRequests.pending, (state, action) => {
        state.PurchaseRequestsButtonSpinner = true;
      }).addCase(updatePurchaseRequests.fulfilled, (state, action) => {
        state.PurchaseRequestsButtonSpinner = false;
        state.PurchaseRequestsErrors = {}
      }).addCase(updatePurchaseRequests.rejected, (state, action) => {
        state.PurchaseRequestsShowMessage = true;
        state.PurchaseRequestsButtonSpinner = false;
        state.PurchaseRequestsErrors = JSON.parse(action.error.message); 
      })
      .addCase(getPurchaseRequests.pending, (state, action) => {
        state.PurchaseRequestsButtonSpinner = true;
        state.PurchaseRequestsTableLoading = true;
      }).addCase(getPurchaseRequests.fulfilled, (state, action) => {
        state.PurchaseRequestsButtonSpinner = false;
        state.PurchaseRequestsTableLoading = false;
        state.PurchaseRequestsResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getPurchaseRequests.rejected, (state, action) => {
        state.PurchaseRequestsShowMessage = true; 
        state.PurchaseRequestsButtonSpinner = false;
        state.PurchaseRequestsTableLoading = false;
        state.PurchaseRequestsErrors = JSON.parse(action.error.message); 
      })
      .addCase(viewPurchaseRequests.pending, (state, action) => {
        state.ViewPurchaseRequestsLoader = true;
        state.ViewPurchaseRequestsData = [];
      }).addCase(viewPurchaseRequests.fulfilled, (state, action) => {
        state.ViewPurchaseRequestsLoader = false;
        state.ViewPurchaseRequestsData = action.payload;
      }).addCase(viewPurchaseRequests.rejected, (state, action) => {
        state.ViewPurchaseRequestsLoader = false;
        state.ViewPurchaseRequestsData = [];
      })
      .addCase(deletePurchaseRequests.pending, (state, action) => {
        state.PurchaseRequestsTableLoading = true;
      }).addCase(deletePurchaseRequests.fulfilled, (state, action) => {
        state.PurchaseRequestsTableLoading = false;
      }).addCase(deletePurchaseRequests.rejected, (state, action) => {
        state.PurchaseRequestsTableLoading = false;
      })
      .addCase(deleteProcurementItem.pending, (state, action) => {
        // state.PurchaseRequestsTableLoading = true;
      }).addCase(deleteProcurementItem.fulfilled, (state, action) => {
        // state.PurchaseRequestsTableLoading = false;
      }).addCase(deleteProcurementItem.rejected, (state, action) => {
        // state.PurchaseRequestsTableLoading = false;
      });
  },
});

export const { 
  onCloseError, 
  setColumnSearch, 
  PurchaseRequestsAddDrawerStatus, 
  PurchaseRequestsEditWithDrawerStatus, 
  updateSortFilters,
  setPurchaseRequestsViewDrawer
} = managePurchaseRequestsSlice.actions;

export default managePurchaseRequestsSlice.reducer;
