import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GRADE_LEVEL, GRADE_LEVEL_API_URL, GET_ALL_GRADE_LEVEL_API_URL, UPDATE_GRADE_SORT_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  GradeLevelErrors: {},
  permission: permission?.grade_level ?? [],
  GradeLevelShowMessage: null,
  GradeLevelResult: [],
  GradeLevelButtonSpinner: false,
  GradeLevelTableLoading: true,
  GradeLevelPageLoading: true,
  GradeLevelAddDrawer: false,
  GradeLevelEditData: [],
  GradeLevelButtonAndModelLabel: setLocale('grade_level.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewGradeLevelData: [],
  ViewGradeLevelLoader: true,
  DrawerStatus: 0,
};

export const createGradeLevel = createAsyncThunk(
  "createGradeLevel",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, GRADE_LEVEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getGradeLevel = createAsyncThunk(
  "getGradeLevel",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_GRADE_LEVEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const updateGradeSort = createAsyncThunk(
  "updateGradeSort",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, UPDATE_GRADE_SORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewGradeLevel = createAsyncThunk(
  "viewGradeLevel",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, GRADE_LEVEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteGradeLevel = createAsyncThunk(
  "deleteGradeLevel",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, GRADE_LEVEL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageGradeLevelSlice = createSlice({
  name: GRADE_LEVEL,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.GradeLevelErrors = {};
    },
    GradeLevelAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.GradeLevelButtonSpinner = false;
      }
      // state.GradeLevelErrors = {};
      state.GradeLevelAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.GradeLevelEditData = [];
      state.GradeLevelButtonAndModelLabel = setLocale('grade_level.add');
    },
    GradeLevelEditWithDrawerStatus: (state, action) => {
      // state.GradeLevelErrors = {};
      state.GradeLevelAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.GradeLevelEditData = action.payload.data;
      state.GradeLevelButtonAndModelLabel = setLocale('grade_level.edit');
      console.log(state.GradeLevelEditData);
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGradeLevel.pending, (state, action) => {
        state.DrawerStatus = 0
        state.GradeLevelButtonSpinner = true;
      }).addCase(createGradeLevel.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.GradeLevelButtonSpinner = false;
        state.GradeLevelErrors = {}
      }).addCase(createGradeLevel.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.GradeLevelShowMessage = true;
        state.GradeLevelButtonSpinner = false;
        state.GradeLevelErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getGradeLevel.pending, (state, action) => {
        state.GradeLevelButtonSpinner = true;
        state.GradeLevelTableLoading = true;
        state.GradeLevelPageLoading = true;
      }).addCase(getGradeLevel.fulfilled, (state, action) => {
        state.GradeLevelButtonSpinner = false;
        state.GradeLevelPageLoading = false;
        state.GradeLevelTableLoading = false;
        state.GradeLevelResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getGradeLevel.rejected, (state, action) => {
        state.GradeLevelPageLoading = false;
        state.GradeLevelShowMessage = true; // Set the showMessage flag to display the errors
        state.GradeLevelButtonSpinner = false;
        state.GradeLevelTableLoading = false;
        state.GradeLevelErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(updateGradeSort.pending, (state, action) => {
        state.GradeLevelTableLoading = true;
      }).addCase(updateGradeSort.fulfilled, (state, action) => {
        state.GradeLevelTableLoading = false;
      }).addCase(updateGradeSort.rejected, (state, action) => {
        state.GradeLevelTableLoading = false;
      })

      .addCase(viewGradeLevel.pending, (state, action) => {
        state.ViewGradeLevelLoader = true;
        state.ViewGradeLevelData = [];
      }).addCase(viewGradeLevel.fulfilled, (state, action) => {
        state.ViewGradeLevelLoader = false;
        state.ViewGradeLevelData = action.payload;
      }).addCase(viewGradeLevel.rejected, (state, action) => {
        state.ViewGradeLevelLoader = false;
        state.ViewGradeLevelData = [];
      })
      .addCase(deleteGradeLevel.pending, (state, action) => {

      }).addCase(deleteGradeLevel.fulfilled, (state, action) => {

      }).addCase(deleteGradeLevel.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, GradeLevelAddDrawerStatus, GradeLevelEditWithDrawerStatus, updateSortFilters } = manageGradeLevelSlice.actions;

export default manageGradeLevelSlice.reducer;
