import {
	API_BASE_URL,
	FILE_BASE_URL,
	SOCKET_ENDPOINT_URL,
	WS_HOST
} from 'constants/ApiConstant';

const dev = {
	WS_HOST: WS_HOST,
	SOCKET_ENDPOINT_URL: SOCKET_ENDPOINT_URL,
	API_ENDPOINT_URL: API_BASE_URL,
	FILE_ENDPOINT_URL: FILE_BASE_URL,
};

const prod = {
	WS_HOST: WS_HOST,
	SOCKET_ENDPOINT_URL: SOCKET_ENDPOINT_URL,
	API_ENDPOINT_URL: API_BASE_URL,
	FILE_ENDPOINT_URL: FILE_BASE_URL,
};

const test = {
	WS_HOST: WS_HOST,
	SOCKET_ENDPOINT_URL: SOCKET_ENDPOINT_URL,
	API_ENDPOINT_URL: API_BASE_URL,
	FILE_ENDPOINT_URL: FILE_BASE_URL,
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
