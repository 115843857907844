import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/login',
		method: 'post',
		data
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/register',
		method: 'post',
		data
	})
}
//
AuthService.signOutRequest = function () {
	return fetch({
		url: '/logout',
		method: 'post',
	})
}
AuthService.forgotPassword = function (data) {
	return fetch({
		url: '/forgot-password',
		method: 'post',
		data: data
	})
}
AuthService.resetPassword = function (data) {
	return fetch({
		url: '/reset-password',
		method: 'post',
		data: data
	})
}

AuthService.changeUserOrga = function (data) {
	return fetch({
		url: '/refresh',
		method: 'post',
		data: data
	})
}

AuthService.verifyUserDevice = function (data) {
	return fetch({
		url: '/verify-user-device',
		method: 'post',
		data
	})
}
AuthService.resendCode = function (data) {
	return fetch({
		url: '/resend-user-code',
		method: 'post',
		data
	})
}
/**
 * Login as User
 */
AuthService.loginAsUser = function (data) {
	return fetch({
		url: '/login-as-user',
		method: 'post',
		data
	})
}
/**
 * Leave Impersonate user
 */
AuthService.leaveImpersonateUser = function (data) {
	return fetch({
		url: '/leave-impersonate-user',
		method: 'post',
		data
	})
}


export default AuthService;