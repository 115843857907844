import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PAYMENT_MODE, PAYMENT_MODE_API_URL, GET_ALL_PAYMENT_MODE_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  PaymentModeErrors: {},
  permission: permission?.payment_mode ?? [],
  PaymentModeShowMessage: null,
  PaymentModeResult: [],
  PaymentModeButtonSpinner: false,
  PaymentModeTableLoading: true,
  PaymentModeAddDrawer: false,
  PaymentModeEditData: [],
  PaymentModeButtonAndModelLabel: setLocale('payment_mode.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewPaymentModeData: [],
  ViewPaymentModeLoader: true,
  DrawerStatus: 0,
};

export const createPaymentMode = createAsyncThunk(
  "createPaymentMode",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, PAYMENT_MODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getPaymentMode = createAsyncThunk(
  "getPaymentMode",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_PAYMENT_MODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewPaymentMode = createAsyncThunk(
  "viewPaymentMode",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, PAYMENT_MODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deletePaymentMode = createAsyncThunk(
  "deletePaymentMode",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, PAYMENT_MODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const managePaymentModeSlice = createSlice({
  name: PAYMENT_MODE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.PaymentModeErrors = {};
    },
    PaymentModeAddDrawerStatus: (state, action) => {
      // state.PaymentModeErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.PaymentModeAddDrawer = action.payload.status;
      state.PaymentModeEditData = [];
      state.PaymentModeButtonAndModelLabel = setLocale('payment_mode.add');
    },
    PaymentModeEditWithDrawerStatus: (state, action) => {
      // state.PaymentModeErrors = {};
      state.PaymentModeAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.PaymentModeEditData = action.payload.data;
      state.PaymentModeButtonAndModelLabel = setLocale('payment_mode.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPaymentMode.pending, (state, action) => {
        state.DrawerStatus = 0
        state.PaymentModeButtonSpinner = true;
      }).addCase(createPaymentMode.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.PaymentModeButtonSpinner = false;
        state.PaymentModeErrors = {}
      }).addCase(createPaymentMode.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.PaymentModeShowMessage = true;
        state.PaymentModeButtonSpinner = false;
        state.PaymentModeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getPaymentMode.pending, (state, action) => {
        state.PaymentModeButtonSpinner = true;
        state.PaymentModeTableLoading = true;
      }).addCase(getPaymentMode.fulfilled, (state, action) => {
        state.PaymentModeButtonSpinner = false;
        state.PaymentModeTableLoading = false;
        state.PaymentModeResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getPaymentMode.rejected, (state, action) => {
        state.PaymentModeShowMessage = true; // Set the showMessage flag to display the errors
        state.PaymentModeButtonSpinner = false;
        state.PaymentModeTableLoading = false;
        state.PaymentModeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewPaymentMode.pending, (state, action) => {
        state.ViewPaymentModeLoader = true;
        state.ViewPaymentModeData = [];
      }).addCase(viewPaymentMode.fulfilled, (state, action) => {
        state.ViewPaymentModeLoader = false;
        state.ViewPaymentModeData = action.payload;
      }).addCase(viewPaymentMode.rejected, (state, action) => {
        state.ViewPaymentModeLoader = false;
        state.ViewPaymentModeData = [];
      })
      .addCase(deletePaymentMode.pending, (state, action) => {

      }).addCase(deletePaymentMode.fulfilled, (state, action) => {

      }).addCase(deletePaymentMode.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, PaymentModeAddDrawerStatus, PaymentModeEditWithDrawerStatus, updateSortFilters } = managePaymentModeSlice.actions;

export default managePaymentModeSlice.reducer;
