import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {ATTENDANCE_MODIFICATION, ATTENDANCE_MODIFICATION_API_URL, GET_ALL_ATTENDANCE_MODIFICATION_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  AttendanceModificationErrors       : {},
  permission: permission?.attendance_modification ?? [],
  AttendanceModificationShowMessage  : null,
  AttendanceModificationResult       : [],
  AttendanceModificationButtonSpinner : false,
  AttendanceModificationTableLoading : true,
  AttendanceModificationAddDrawer    : false,
  AttendanceModificationEditData     : [],
  AttendanceModificationButtonAndModelLabel  : setLocale('attendancemodification.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewAttendanceModificationData: [],
    ViewAttendanceModificationLoader: true,
};

export const createAttendanceModification = createAsyncThunk(
    "createAttendanceModification",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, ATTENDANCE_MODIFICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getAttendanceModification = createAsyncThunk(
    "getAttendanceModification",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_ATTENDANCE_MODIFICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewAttendanceModification = createAsyncThunk(
  "viewAttendanceModification",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, ATTENDANCE_MODIFICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteAttendanceModification = createAsyncThunk(
  "deleteAttendanceModification",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ATTENDANCE_MODIFICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageAttendanceModificationSlice = createSlice({
  name: ATTENDANCE_MODIFICATION,
  initialState,
  reducers: {
    AttendanceModificationAddDrawerStatus: (state, action) => {
      state.AttendanceModificationErrors      = {};
      state.AttendanceModificationAddDrawer   = action.payload;
      state.AttendanceModificationEditData    = [];
      state.AttendanceModificationButtonAndModelLabel = setLocale('attendancemodification.add');
    },
    AttendanceModificationEditWithDrawerStatus: (state, action) => {
      state.AttendanceModificationErrors = {};
      state.AttendanceModificationAddDrawer = true;
      state.AttendanceModificationEditData = action.payload;
      state.AttendanceModificationButtonAndModelLabel = setLocale('attendancemodification.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAttendanceModification.pending, (state, action) => {
        state.AttendanceModificationButtonSpinner = true;
      }).addCase(createAttendanceModification.fulfilled, (state, action) => {
        state.AttendanceModificationButtonSpinner = false;
        state.AttendanceModificationErrors = {}
      }).addCase(createAttendanceModification.rejected, (state, action) => {
        state.AttendanceModificationShowMessage = true;
        state.AttendanceModificationButtonSpinner = false;
        state.AttendanceModificationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getAttendanceModification.pending, (state, action) => {
        state.AttendanceModificationButtonSpinner = true;
        state.AttendanceModificationTableLoading = true;
      }).addCase(getAttendanceModification.fulfilled, (state, action) => {
        state.AttendanceModificationButtonSpinner = false;
        state.AttendanceModificationTableLoading = false;
        state.AttendanceModificationResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.total,
            current: action.payload.current_page,
            pageSize: action.payload.per_page
        };
      }).addCase(getAttendanceModification.rejected, (state, action) => {
        state.AttendanceModificationShowMessage = true; // Set the showMessage flag to display the errors
        state.AttendanceModificationButtonSpinner = false;
        state.AttendanceModificationTableLoading = false;
        state.AttendanceModificationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewAttendanceModification.pending, (state, action) => {
        state.ViewAttendanceModificationLoader = true;
        state.ViewAttendanceModificationData = [];
      }).addCase(viewAttendanceModification.fulfilled, (state, action) => {
        state.ViewAttendanceModificationLoader = false;
        state.ViewAttendanceModificationData = action.payload;
      }).addCase(viewAttendanceModification.rejected, (state, action) => {
        state.ViewAttendanceModificationLoader = false;
        state.ViewAttendanceModificationData = [];
      })
      .addCase(deleteAttendanceModification.pending, (state, action) => {

      }).addCase(deleteAttendanceModification.fulfilled, (state, action) => {

      }).addCase(deleteAttendanceModification.rejected, (state, action) => {

      });
  },
});

export const {  AttendanceModificationAddDrawerStatus, AttendanceModificationEditWithDrawerStatus, updateSortFilters } = manageAttendanceModificationSlice.actions;

export default manageAttendanceModificationSlice.reducer;
