import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SETUP_ORGANIZATION, SETUP_ORGANIZATION_API_URL } from 'constants/AppConstants';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
  SetupOrganizationErrors: {},
  SetupOrganizationShowMessage: null,
  SetupOrganizationResult: [],
  SetupOrganizationButtonSpiner: false,
  SetupOrganizationAddDrawer: false,
  SetupOrganizationEditData: [],
  SetupOrganizationButtonAndModelLabel: 'Add Setup Organization',
};

export const createSetupOrganization = createAsyncThunk(
  "createSetupOrganization",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, SETUP_ORGANIZATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getSetupOrganization = createAsyncThunk(
  "getSetupOrganization",
  async (data) => {
    try {
      const response = await CommonService.getAll(SETUP_ORGANIZATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteSetupOrganization = createAsyncThunk(
  "deleteSetupOrganization",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, SETUP_ORGANIZATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageSetupOrganizationSlice = createSlice({
  name: SETUP_ORGANIZATION,
  initialState,
  reducers: {
    SetupOrganizationAddDrawerStatus: (state, action) => {
      state.SetupOrganizationErrors = {};
      state.SetupOrganizationAddDrawer = action.payload;
      state.SetupOrganizationEditData = [];
      state.SetupOrganizationButtonAndModelLabel = 'Add Setup Organization';
    },
    SetupOrganizationEditWithDrawerStatus: (state, action) => {
      console.log(action);
      state.SetupOrganizationErrors = {};
      state.SetupOrganizationAddDrawer = true;
      state.SetupOrganizationEditData = action.payload;
      state.SetupOrganizationButtonAndModelLabel = 'Edit Setup Organization';

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSetupOrganization.pending, (state, action) => {
        state.SetupOrganizationButtonSpiner = true;
      }).addCase(createSetupOrganization.fulfilled, (state, action) => {
        state.SetupOrganizationButtonSpiner = false;
        state.SetupOrganizationErrors = {}
      }).addCase(createSetupOrganization.rejected, (state, action) => {
        state.SetupOrganizationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.SetupOrganizationShowMessage = true; // Set the showMessage flag to display the errors
        state.SetupOrganizationButtonSpiner = false;
      }).addCase(getSetupOrganization.pending, (state, action) => {
        state.SetupOrganizationButtonSpiner = true;
      }).addCase(getSetupOrganization.fulfilled, (state, action) => {
        state.SetupOrganizationButtonSpiner = false;
        state.SetupOrganizationResult = action.payload;
        state.SetupOrganizationErrors = {}
      }).addCase(getSetupOrganization.rejected, (state, action) => {
        state.SetupOrganizationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.SetupOrganizationShowMessage = true; // Set the showMessage flag to display the errors
        state.SetupOrganizationButtonSpiner = false;
      });
  },
});

export const { SetupOrganizationAddDrawerStatus, SetupOrganizationEditWithDrawerStatus } = manageSetupOrganizationSlice.actions;

export default manageSetupOrganizationSlice.reducer;
