import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {LOCATIONS, LOCATIONS_API_URL, GET_ALL_LOCATIONS_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  LocationsErrors       : {},
  permission: permission?.locations ?? [],
  LocationsShowMessage  : null,
  LocationsResult       : [],
  LocationsButtonSpinner : false,
  LocationsTableLoading : true,
  LocationsAddDrawer    : false,
  LocationsEditData     : [],
  LocationsButtonAndModelLabel  : setLocale('locations.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewLocationsData: [],
    ViewLocationsLoader: true,
    DrawerStatus: 0,
};

export const createLocations = createAsyncThunk(
    "createLocations",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, LOCATIONS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getLocations = createAsyncThunk(
    "getLocations",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_LOCATIONS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewLocations = createAsyncThunk(
  "viewLocations",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, LOCATIONS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteLocations = createAsyncThunk(
  "deleteLocations",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, LOCATIONS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageLocationsSlice = createSlice({
  name: LOCATIONS,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.RelationshipErrors = {};
    },
    LocationsAddDrawerStatus: (state, action) => {
        if (action.payload === false) {
            state.LocationsButtonSpinner = false;
      }
      state.LocationsAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.LocationsEditData    = [];
      state.LocationsButtonAndModelLabel = setLocale('locations.add');
    },
    LocationsEditWithDrawerStatus: (state, action) => {
      state.LocationsAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.LocationsEditData = action.payload.data;
      state.LocationsButtonAndModelLabel = setLocale('locations.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLocations.pending, (state, action) => {
        state.DrawerStatus = 0
        state.LocationsButtonSpinner = true;
      }).addCase(createLocations.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.LocationsButtonSpinner = false;
        state.LocationsErrors = {}
      }).addCase(createLocations.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.LocationsShowMessage = true;
        state.LocationsButtonSpinner = false;
        state.LocationsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getLocations.pending, (state, action) => {
        state.LocationsButtonSpinner = true;
        state.LocationsTableLoading = true;
      }).addCase(getLocations.fulfilled, (state, action) => {
        state.LocationsButtonSpinner = false;
        state.LocationsTableLoading = false;
        state.LocationsResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getLocations.rejected, (state, action) => {
        state.LocationsShowMessage = true; // Set the showMessage flag to display the errors
        state.LocationsButtonSpinner = false;
        state.LocationsTableLoading = false;
        state.LocationsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewLocations.pending, (state, action) => {
        state.ViewLocationsLoader = true;
        state.ViewLocationsData = [];
      }).addCase(viewLocations.fulfilled, (state, action) => {
        state.ViewLocationsLoader = false;
        state.ViewLocationsData = action.payload;
      }).addCase(viewLocations.rejected, (state, action) => {
        state.ViewLocationsLoader = false;
        state.ViewLocationsData = [];
      })
      .addCase(deleteLocations.pending, (state, action) => {
        state.LocationsTableLoading = true;
      }).addCase(deleteLocations.fulfilled, (state, action) => {
        state.LocationsTableLoading = false;
      }).addCase(deleteLocations.rejected, (state, action) => {
        state.LocationsTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, LocationsAddDrawerStatus, LocationsEditWithDrawerStatus, updateSortFilters } = manageLocationsSlice.actions;

export default manageLocationsSlice.reducer;
