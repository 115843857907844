import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SCHOOL_CATEGORY, SCHOOL_CATEGORY_API_URL, GET_ALL_SCHOOL_CATEGORY_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  SchoolCategoryErrors: {},
  permission: permission?.school_category ?? [],
  SchoolCategoryShowMessage: null,
  SchoolCategoryResult: [],
  SchoolCategoryButtonSpinner: false,
  SchoolCategoryTableLoading: true,
  SchoolCategoryAddDrawer: false,
  SchoolCategoryEditData: [],
  SchoolCategoryButtonAndModelLabel: setLocale('school_category.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewSchoolCategoryData: [],
  ViewSchoolCategoryLoader: true,
  DrawerStatus: 0,
};

export const createSchoolCategory = createAsyncThunk(
  "createSchoolCategory",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, SCHOOL_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getSchoolCategory = createAsyncThunk(
  "getSchoolCategory",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_SCHOOL_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewSchoolCategory = createAsyncThunk(
  "viewSchoolCategory",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, SCHOOL_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteSchoolCategory = createAsyncThunk(
  "deleteSchoolCategory",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, SCHOOL_CATEGORY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageSchoolCategorySlice = createSlice({
  name: SCHOOL_CATEGORY,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.SchoolCategoryErrors = {};
    },
    SchoolCategoryAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.SchoolCategoryButtonSpinner = false;
      }
      // state.SchoolCategoryErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.SchoolCategoryAddDrawer = action.payload.status;
      state.SchoolCategoryEditData = [];
      state.SchoolCategoryButtonAndModelLabel = setLocale('school_category.add');
    },
    SchoolCategoryEditWithDrawerStatus: (state, action) => {
      // state.SchoolCategoryErrors = {};
      state.SchoolCategoryAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.SchoolCategoryEditData = action.payload.data;
      state.SchoolCategoryButtonAndModelLabel = setLocale('school_category.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSchoolCategory.pending, (state, action) => {
        state.DrawerStatus = 0
        state.SchoolCategoryButtonSpinner = true;
      }).addCase(createSchoolCategory.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.SchoolCategoryButtonSpinner = false;
        state.SchoolCategoryErrors = {}
      }).addCase(createSchoolCategory.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.SchoolCategoryShowMessage = true;
        state.SchoolCategoryButtonSpinner = false;
        state.SchoolCategoryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getSchoolCategory.pending, (state, action) => {
        state.SchoolCategoryButtonSpinner = true;
        state.SchoolCategoryTableLoading = true;
      }).addCase(getSchoolCategory.fulfilled, (state, action) => {
        state.SchoolCategoryButtonSpinner = false;
        state.SchoolCategoryTableLoading = false;
        state.SchoolCategoryResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getSchoolCategory.rejected, (state, action) => {
        state.SchoolCategoryShowMessage = true; // Set the showMessage flag to display the errors
        state.SchoolCategoryButtonSpinner = false;
        state.SchoolCategoryTableLoading = false;
        state.SchoolCategoryErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewSchoolCategory.pending, (state, action) => {
        state.ViewSchoolCategoryLoader = true;
        state.ViewSchoolCategoryData = [];
      }).addCase(viewSchoolCategory.fulfilled, (state, action) => {
        state.ViewSchoolCategoryLoader = false;
        state.ViewSchoolCategoryData = action.payload;
      }).addCase(viewSchoolCategory.rejected, (state, action) => {
        state.ViewSchoolCategoryLoader = false;
        state.ViewSchoolCategoryData = [];
      })
      .addCase(deleteSchoolCategory.pending, (state, action) => {

      }).addCase(deleteSchoolCategory.fulfilled, (state, action) => {

      }).addCase(deleteSchoolCategory.rejected, (state, action) => {

      });
  },
});

export const { onCloseError, setColumnSearch, SchoolCategoryAddDrawerStatus, SchoolCategoryEditWithDrawerStatus, updateSortFilters } = manageSchoolCategorySlice.actions;

export default manageSchoolCategorySlice.reducer;
