import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CONTRACT_SIGN, CONTRACT_SIGN_API_URL, DOWNLOAD_CONTRACT_PDF, GET_ALL_CONTRACT_SIGN_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import { API_BASE_URL } from "constants/ApiConstant";
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ContractSignErrors: {},
  permission: permission?.contract_sign ?? [],
  ContractSignShowMessage: null,
  ContractSignResult: [],
  ContractSignButtonSpinner: false,
  ContractSignTableLoading: true,
  ContractSignAddDrawer: false,
  ContractSignEditData: [],
  ContractSignButtonAndModelLabel: setLocale('contractsign.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewContractSignData: [],
  ContractDownloadSpinner: false,
  ViewContractSignLoader: true,
};

export const createContractSign = createAsyncThunk(
  "createContractSign",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, CONTRACT_SIGN_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getContractSign = createAsyncThunk(
  "getContractSign",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_CONTRACT_SIGN_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewContractSign = createAsyncThunk(
  "viewContractSign",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, CONTRACT_SIGN_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteContractSign = createAsyncThunk(
  "deleteContractSign",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CONTRACT_SIGN_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const downloadContract = createAsyncThunk(
  'downloadContract',
  async (filename) => {
    try {
      const response = await CommonService.showOne(filename, DOWNLOAD_CONTRACT_PDF);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageContractSignSlice = createSlice({
  name: CONTRACT_SIGN,
  initialState,
  reducers: {
    ContractSignAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.ContractSignButtonSpinner = false;
      }
      state.ContractSignErrors = {};
      state.ContractSignAddDrawer = action.payload;
      state.ContractSignEditData = [];
      state.ContractSignButtonAndModelLabel = setLocale('contractsign.add');
    },
    ContractSignEditWithDrawerStatus: (state, action) => {
      state.ContractSignErrors = {};
      state.ContractSignAddDrawer = true;
      state.ContractSignEditData = action.payload;
      state.ContractSignButtonAndModelLabel = setLocale('contractsign.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    videPDF(state, action) {
      const dataToPass = {
        id: action.payload.enc_id
      };

      // Constructing the query string from the data
      const queryString = Object.keys(dataToPass)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(dataToPass[key])}`)
        .join('&');

      // Constructing the final URL with the query string
      const url = `${API_BASE_URL}/download-contract-pdf?${queryString}`;

      // Opening a new window with the specified URL
      window.open(url, '_blank');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createContractSign.pending, (state, action) => {
        state.ContractSignButtonSpinner = true;
      }).addCase(createContractSign.fulfilled, (state, action) => {
        state.ContractSignButtonSpinner = false;
        state.ContractSignErrors = {}
      }).addCase(createContractSign.rejected, (state, action) => {
        state.ContractSignShowMessage = true;
        state.ContractSignButtonSpinner = false;
        state.ContractSignErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getContractSign.pending, (state, action) => {
        state.ContractSignButtonSpinner = true;
        state.ContractSignTableLoading = true;
      }).addCase(getContractSign.fulfilled, (state, action) => {
        state.ContractSignButtonSpinner = false;
        state.ContractSignTableLoading = false;
        state.ContractSignResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getContractSign.rejected, (state, action) => {
        state.ContractSignShowMessage = true; // Set the showMessage flag to display the errors
        state.ContractSignButtonSpinner = false;
        state.ContractSignTableLoading = false;
        state.ContractSignErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewContractSign.pending, (state, action) => {
        state.ViewContractSignLoader = true;
        state.ViewContractSignData = [];
      }).addCase(viewContractSign.fulfilled, (state, action) => {
        state.ViewContractSignLoader = false;
        state.ViewContractSignData = action.payload;
      }).addCase(viewContractSign.rejected, (state, action) => {
        state.ViewContractSignLoader = false;
        state.ViewContractSignData = [];
      })
      .addCase(deleteContractSign.pending, (state, action) => {

      }).addCase(deleteContractSign.fulfilled, (state, action) => {

      }).addCase(deleteContractSign.rejected, (state, action) => {

      })
      .addCase(downloadContract.pending, (state, action) => {
        state.ContractDownloadSpinner = true;
      }).addCase(downloadContract.fulfilled, (state, action) => {
        state.ContractDownloadSpinner = false;

        // Open the file in a new window
        // window.open(action.payload, '_blank');

        // OR, trigger a download
        window.location.href = action.payload;

      }).addCase(downloadContract.rejected, (state, action) => {
        state.ContractDownloadSpinner = false;
      });
  },
});

export const { videPDF, setColumnSearch, ContractSignAddDrawerStatus, ContractSignEditWithDrawerStatus, updateSortFilters } = manageContractSignSlice.actions;

export default manageContractSignSlice.reducer;
