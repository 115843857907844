import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {CHAT, CHAT_API_URL, GET_ALL_CHAT_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ChatErrors       : {},
  permission: permission?.chat ?? [],
  ChatShowMessage  : null,
  ChatResult       : [],
  ChatButtonSpinner : false,
  ChatTableLoading : true,
  ChatAddDrawer    : false,
  ChatEditData     : [],
  ChatButtonAndModelLabel  : setLocale('chat.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewChatData: [],
    ViewChatLoader: true,
};

export const createChat = createAsyncThunk(
    "createChat",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, CHAT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getChat = createAsyncThunk(
    "getChat",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_CHAT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewChat = createAsyncThunk(
  "viewChat",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, CHAT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteChat = createAsyncThunk(
  "deleteChat",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CHAT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageChatSlice = createSlice({
  name: CHAT,
  initialState,
  reducers: {
    ChatAddDrawerStatus: (state, action) => {
        if (action.payload === false) {
            state.ChatButtonSpinner = false;
      }
      state.ChatErrors      = {};
      state.ChatAddDrawer   = action.payload;
      state.ChatEditData    = [];
      state.ChatButtonAndModelLabel = setLocale('chat.add');
    },
    ChatEditWithDrawerStatus: (state, action) => {
      state.ChatErrors = {};
      state.ChatAddDrawer = true;
      state.ChatEditData = action.payload;
      state.ChatButtonAndModelLabel = setLocale('chat.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createChat.pending, (state, action) => {
        state.ChatButtonSpinner = true;
      }).addCase(createChat.fulfilled, (state, action) => {
        state.ChatButtonSpinner = false;
        state.ChatErrors = {}
      }).addCase(createChat.rejected, (state, action) => {
        state.ChatShowMessage = true;
        state.ChatButtonSpinner = false;
        state.ChatErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getChat.pending, (state, action) => {
        state.ChatButtonSpinner = true;
        state.ChatTableLoading = true;
      }).addCase(getChat.fulfilled, (state, action) => {
        state.ChatButtonSpinner = false;
        state.ChatTableLoading = false;
        state.ChatResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.total,
            current: action.payload.current_page,
            pageSize: action.payload.per_page
        };
      }).addCase(getChat.rejected, (state, action) => {
        state.ChatShowMessage = true; // Set the showMessage flag to display the errors
        state.ChatButtonSpinner = false;
        state.ChatTableLoading = false;
        state.ChatErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewChat.pending, (state, action) => {
        state.ViewChatLoader = true;
        state.ViewChatData = [];
      }).addCase(viewChat.fulfilled, (state, action) => {
        state.ViewChatLoader = false;
        state.ViewChatData = action.payload;
      }).addCase(viewChat.rejected, (state, action) => {
        state.ViewChatLoader = false;
        state.ViewChatData = [];
      })
      .addCase(deleteChat.pending, (state, action) => {

      }).addCase(deleteChat.fulfilled, (state, action) => {

      }).addCase(deleteChat.rejected, (state, action) => {

      });
  },
});

export const {  setColumnSearch, ChatAddDrawerStatus, ChatEditWithDrawerStatus, updateSortFilters } = manageChatSlice.actions;

export default manageChatSlice.reducer;
