import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {MACHINE, MACHINE_API_URL, GET_ALL_MACHINE_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  MachineErrors       : {},
  permission: permission?.machine ?? [],
  MachineShowMessage  : null,
  MachineResult       : [],
  MachineButtonSpinner : false,
  MachineTableLoading : true,
  MachineAddDrawer    : false,
  MachineEditData     : [],
  MachineButtonAndModelLabel  : setLocale('machine.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewMachineData: [],
    ViewMachineLoader: true,
    DrawerStatus: 0,
};

export const createMachine = createAsyncThunk(
    "createMachine",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, MACHINE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getMachine = createAsyncThunk(
    "getMachine",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_MACHINE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewMachine = createAsyncThunk(
  "viewMachine",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, MACHINE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteMachine = createAsyncThunk(
  "deleteMachine",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, MACHINE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageMachineSlice = createSlice({
  name: MACHINE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.MachineErrors = {};
    },
    MachineAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.MachineButtonSpinner = false;
      }
      state.MachineAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.MachineEditData    = [];
      state.MachineButtonAndModelLabel = setLocale('machine.add');
    },
    MachineEditWithDrawerStatus: (state, action) => {
      state.MachineAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.MachineEditData = action.payload.data;
      state.MachineButtonAndModelLabel = setLocale('machine.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMachine.pending, (state, action) => {
        state.DrawerStatus = 0
        state.MachineButtonSpinner = true;
      }).addCase(createMachine.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.MachineButtonSpinner = false;
        state.MachineErrors = {}
      }).addCase(createMachine.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.MachineShowMessage = true;
        state.MachineButtonSpinner = false;
        state.MachineErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getMachine.pending, (state, action) => {
        state.MachineButtonSpinner = true;
        state.MachineTableLoading = true;
      }).addCase(getMachine.fulfilled, (state, action) => {
        state.MachineButtonSpinner = false;
        state.MachineTableLoading = false;
        state.MachineResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getMachine.rejected, (state, action) => {
        state.MachineShowMessage = true; // Set the showMessage flag to display the errors
        state.MachineButtonSpinner = false;
        state.MachineTableLoading = false;
        state.MachineErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewMachine.pending, (state, action) => {
        state.ViewMachineLoader = true;
        state.ViewMachineData = [];
      }).addCase(viewMachine.fulfilled, (state, action) => {
        state.ViewMachineLoader = false;
        state.ViewMachineData = action.payload;
      }).addCase(viewMachine.rejected, (state, action) => {
        state.ViewMachineLoader = false;
        state.ViewMachineData = [];
      })
      .addCase(deleteMachine.pending, (state, action) => {
        state.MachineTableLoading = true;
      }).addCase(deleteMachine.fulfilled, (state, action) => {
        state.MachineTableLoading = false;
      }).addCase(deleteMachine.rejected, (state, action) => {
        state.MachineTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, MachineAddDrawerStatus, MachineEditWithDrawerStatus, updateSortFilters } = manageMachineSlice.actions;

export default manageMachineSlice.reducer;
