import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ALL_ROLE_PERMISSION_API_URL, GET_EXCLUDE_ORGANIZATION_ROLE_URL, ROLE_PERMISSION, ROLE_PERMISSION_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  RolePermissionErrors: {},
  permission: permission?.role_permission ?? [],
  RolePermissionShowMessage: null,
  RolePermissionResult: [],
  RolePermissionButtonSpinner: false,
  RolePermissionTableLoading: true,
  RolePermissionAddDrawer: false,
  RolePermissionEditData: {},
  RolePermissionButtonAndModelLabel: 'Add Role Permission',
  DrawerStatus: 0,
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ExcludeOrganizationRole: [],
};

export const createRolePermission = createAsyncThunk(
  "createRolePermission",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, ROLE_PERMISSION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getRolePermission = createAsyncThunk(
  "getRolePermission",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_ROLE_PERMISSION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getExcludeOrganizationRole = createAsyncThunk(
  "getExcludeOrganizationRole",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_EXCLUDE_ORGANIZATION_ROLE_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteRolePermission = createAsyncThunk(
  "deleteRolePermission",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ROLE_PERMISSION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageRolePermissionSlice = createSlice({
  name: ROLE_PERMISSION,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.RolePermissionErrors = {};
      state.ExcludeOrganizationRole = [];
    },
    RolePermissionAddDrawerStatus: (state, action) => {
      state.DrawerStatus = action.payload.errorStatus;
      state.RolePermissionAddDrawer = action.payload.status;
      state.RolePermissionEditData = {};
      state.RolePermissionButtonAndModelLabel = 'Add Role Permission';
    },
    RolePermissionEditWithDrawerStatus: (state, action) => {
      state.DrawerStatus = action.payload.errorStatus;
      state.RolePermissionEditData = action.payload.data;
      state.RolePermissionAddDrawer = true;
      state.RolePermissionButtonAndModelLabel = 'Edit Role Permission';
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRolePermission.pending, (state, action) => {
        state.DrawerStatus = 0;
        state.RolePermissionButtonSpinner = true;
      }).addCase(createRolePermission.fulfilled, (state, action) => {
        state.DrawerStatus = 0;
        state.RolePermissionButtonSpinner = false;
        state.RolePermissionErrors = {}
      }).addCase(createRolePermission.rejected, (state, action) => {
        state.DrawerStatus = 1;
        state.RolePermissionErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.RolePermissionShowMessage = true; // Set the showMessage flag to display the errors
        state.RolePermissionButtonSpinner = false;
      }).addCase(getRolePermission.pending, (state, action) => {
        state.RolePermissionTableLoading = true;
        state.RolePermissionButtonSpinner = true;
      }).addCase(getRolePermission.fulfilled, (state, action) => {
        state.RolePermissionTableLoading = false;
        state.RolePermissionButtonSpinner = false;
        state.RolePermissionResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getRolePermission.rejected, (state, action) => {
        state.RolePermissionTableLoading = false;
        state.RolePermissionShowMessage = true; // Set the showMessage flag to display the errors
        state.RolePermissionButtonSpinner = false;
      }).addCase(getExcludeOrganizationRole.pending, (state, action) => {
        state.ExcludeOrganizationRole = [];
      }).addCase(getExcludeOrganizationRole.fulfilled, (state, action) => {
        state.ExcludeOrganizationRole = action.payload;
      }).addCase(getExcludeOrganizationRole.rejected, (state, action) => {
      });
  },
});

export const { onCloseError, setColumnSearch, updateSortFilters, RolePermissionAddDrawerStatus, RolePermissionEditWithDrawerStatus } = manageRolePermissionSlice.actions;

export default manageRolePermissionSlice.reducer;
