import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {ENROLLMENT_STATUS, ENROLLMENT_STATUS_API_URL, GET_ALL_ENROLLMENT_STATUS_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  EnrollmentStatusErrors       : {},
  permission: permission?.enrollment_status ?? [],
  EnrollmentStatusShowMessage  : null,
  EnrollmentStatusResult       : [],
  EnrollmentStatusButtonSpinner : false,
  EnrollmentStatusTableLoading : true,
  EnrollmentStatusAddDrawer    : false,
  EnrollmentStatusEditData     : [],
  EnrollmentStatusButtonAndModelLabel  : setLocale('enrollment_status.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewEnrollmentStatusData: [],
    ViewEnrollmentStatusLoader: true,
    DrawerStatus: 0,
};

export const createEnrollmentStatus = createAsyncThunk(
    "createEnrollmentStatus",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, ENROLLMENT_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getEnrollmentStatus = createAsyncThunk(
    "getEnrollmentStatus",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_ENROLLMENT_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewEnrollmentStatus = createAsyncThunk(
  "viewEnrollmentStatus",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, ENROLLMENT_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteEnrollmentStatus = createAsyncThunk(
  "deleteEnrollmentStatus",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ENROLLMENT_STATUS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageEnrollmentStatusSlice = createSlice({
  name: ENROLLMENT_STATUS,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.EnrollmentStatusErrors = {};
    },
    EnrollmentStatusAddDrawerStatus: (state, action) => {
      // state.EnrollmentStatusErrors      = {};
      state.EnrollmentStatusAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.EnrollmentStatusEditData    = [];
      state.EnrollmentStatusButtonAndModelLabel = setLocale('enrollment_status.add');
    },
    EnrollmentStatusEditWithDrawerStatus: (state, action) => {
      // state.EnrollmentStatusErrors = {};
      state.EnrollmentStatusAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.EnrollmentStatusEditData = action.payload.data;
      state.EnrollmentStatusButtonAndModelLabel = setLocale('enrollment_status.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEnrollmentStatus.pending, (state, action) => {
        state.DrawerStatus = 0
        state.EnrollmentStatusButtonSpinner = true;
      }).addCase(createEnrollmentStatus.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.EnrollmentStatusButtonSpinner = false;
        state.EnrollmentStatusErrors = {}
      }).addCase(createEnrollmentStatus.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.EnrollmentStatusShowMessage = true;
        state.EnrollmentStatusButtonSpinner = false;
        state.EnrollmentStatusErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getEnrollmentStatus.pending, (state, action) => {
        state.EnrollmentStatusButtonSpinner = true;
        state.EnrollmentStatusTableLoading = true;
      }).addCase(getEnrollmentStatus.fulfilled, (state, action) => {
        state.EnrollmentStatusButtonSpinner = false;
        state.EnrollmentStatusTableLoading = false;
        state.EnrollmentStatusResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getEnrollmentStatus.rejected, (state, action) => {
        state.EnrollmentStatusShowMessage = true; // Set the showMessage flag to display the errors
        state.EnrollmentStatusButtonSpinner = false;
        state.EnrollmentStatusTableLoading = false;
        state.EnrollmentStatusErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewEnrollmentStatus.pending, (state, action) => {
        state.ViewEnrollmentStatusLoader = true;
        state.ViewEnrollmentStatusData = [];
      }).addCase(viewEnrollmentStatus.fulfilled, (state, action) => {
        state.ViewEnrollmentStatusLoader = false;
        state.ViewEnrollmentStatusData = action.payload;
      }).addCase(viewEnrollmentStatus.rejected, (state, action) => {
        state.ViewEnrollmentStatusLoader = false;
        state.ViewEnrollmentStatusData = [];
      })
      .addCase(deleteEnrollmentStatus.pending, (state, action) => {

      }).addCase(deleteEnrollmentStatus.fulfilled, (state, action) => {

      }).addCase(deleteEnrollmentStatus.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, EnrollmentStatusAddDrawerStatus, EnrollmentStatusEditWithDrawerStatus, updateSortFilters } = manageEnrollmentStatusSlice.actions;

export default manageEnrollmentStatusSlice.reducer;
