import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STUDENT_GRADE_BOOK_SLICE, GRADE, GRADE_API_URL, GET_ALL_GRADE_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  GradeErrors: {},
  permission: permission?.grade ?? [],
  GradeShowMessage: null,
  GradeResult: [],
  GradeButtonSpinner: false,
  GradeTableLoading: true,
  GradeAddDrawer: false,
  GradeEditData: {},
  GradeButtonAndModelLabel: setLocale('grade.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewGradeData: [],
  ViewGradeLoader: true,
  DrawerStatus: 0,
};

export const createGrade = createAsyncThunk(
  "createGrade",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, GRADE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getGrade = createAsyncThunk(
  "getGrade",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_GRADE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewGrade = createAsyncThunk(
  "viewGrade",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, GRADE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteGrade = createAsyncThunk(
  "deleteGrade",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, GRADE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageGradeSlice = createSlice({
  name: GRADE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.GradeErrors = {};
    },
    GradeAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.GradeButtonSpinner = false;
      }
      // state.GradeErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.GradeAddDrawer = action.payload.status;
      state.GradeEditData = {};
      state.GradeButtonAndModelLabel = setLocale('grade.add');
    },
    GradeEditWithDrawerStatus: (state, action) => {
      // state.GradeErrors = {};
      state.GradeAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.GradeEditData = action.payload.data;
      state.GradeButtonAndModelLabel = setLocale('grade.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGrade.pending, (state, action) => {
        state.DrawerStatus = 0
        state.GradeButtonSpinner = true;
      }).addCase(createGrade.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.GradeButtonSpinner = false;
        state.GradeErrors = {}
      }).addCase(createGrade.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.GradeShowMessage = true;
        state.GradeButtonSpinner = false;
        state.GradeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getGrade.pending, (state, action) => {
        state.GradeButtonSpinner = true;
        state.GradeTableLoading = true;
      }).addCase(getGrade.fulfilled, (state, action) => {
        state.GradeButtonSpinner = false;
        state.GradeTableLoading = false;
        state.GradeResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getGrade.rejected, (state, action) => {
        state.GradeShowMessage = true; // Set the showMessage flag to display the errors
        state.GradeButtonSpinner = false;
        state.GradeTableLoading = false;
        state.GradeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewGrade.pending, (state, action) => {
        state.ViewGradeLoader = true;
        state.ViewGradeData = [];
      }).addCase(viewGrade.fulfilled, (state, action) => {
        state.ViewGradeLoader = false;
        state.ViewGradeData = action.payload;
      }).addCase(viewGrade.rejected, (state, action) => {
        state.ViewGradeLoader = false;
        state.ViewGradeData = [];
      })
      .addCase(deleteGrade.pending, (state, action) => {

      }).addCase(deleteGrade.fulfilled, (state, action) => {

      }).addCase(deleteGrade.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, GradeAddDrawerStatus, GradeEditWithDrawerStatus, updateSortFilters } = manageGradeSlice.actions;

export default manageGradeSlice.reducer;
