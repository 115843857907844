export const STUDENT_GRADING = 'manageStudentGradingSlice'

export const GET_CLASS_STUDENTS_FOR_GRADES = 'fetch-class-students-for-grades'
// export const ADD_COURSE_GRADE_VATEGORY = 'add-course-grade-category'
// export const ADD_STUDENT_OBTAIN_MARKS = 'add-student-obtained-marks'
// export const FINALIZED_COURSE_GRADE_CATEGORY = 'finalized-course-grade-category'
export const VIEW_SINGLE_STUDENT_GRADE_DETAIL = 'view-student-grade-detail'
export const PRE_LOAD_STUDENT_GRADE = 'pre-load-grading-data'
export const SCHOOL_TERMS = 'get-school-terms'
export const GET_GRADE_CLASSES = 'get-grade-classes'
export const GET_CLASS_COURSES = 'get-class-courses'
export const SINGLE_STUDENT_GRAPH_DATA = 'get-single-student-graph-data'

