import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ATTENDANCE_CODE_API_URL, LEAVE_REQUEST, LEAVE_REQUEST_API_URL, GET_ALL_LEAVE_REQUEST_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  LeaveRequestErrors: {},
  permission: permission?.leave_request ?? [],
  LeaveRequestShowMessage: null,
  LeaveRequestResult: [],
  LeaveRequestButtonSpinner: false,
  LeaveRequestTableLoading: true,
  LeaveRequestAddDrawer: false,
  LeaveRequestEditData: [],
  AttendanceCode: [],
  LeaveRequestButtonAndModelLabel: setLocale('leave_request.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewLeaveRequestData: [],
  ViewLeaveRequestLoader: true,
};

export const createLeaveRequest = createAsyncThunk(
  "createLeaveRequest",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, LEAVE_REQUEST_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getLeaveRequest = createAsyncThunk(
  "getLeaveRequest",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_LEAVE_REQUEST_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getAttendanceCode = createAsyncThunk(
  "getAttendanceCode",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ATTENDANCE_CODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewLeaveRequest = createAsyncThunk(
  "viewLeaveRequest",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, LEAVE_REQUEST_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteLeaveRequest = createAsyncThunk(
  "deleteLeaveRequest",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, LEAVE_REQUEST_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageLeaveRequestSlice = createSlice({
  name: LEAVE_REQUEST,
  initialState,
  reducers: {
    LeaveRequestAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.LeaveRequestButtonSpinner = false;
      }
      state.LeaveRequestErrors = {};
      state.LeaveRequestAddDrawer = action.payload;
      state.LeaveRequestEditData = [];
      state.LeaveRequestButtonAndModelLabel = setLocale('leaverequest.add');
    },
    LeaveRequestEditWithDrawerStatus: (state, action) => {
      state.LeaveRequestErrors = {};
      state.LeaveRequestAddDrawer = true;
      state.LeaveRequestEditData = action.payload;
      state.LeaveRequestButtonAndModelLabel = action.payload?.guardian + '(' + action.payload?.student + ')';
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLeaveRequest.pending, (state, action) => {
        state.LeaveRequestButtonSpinner = true;
      }).addCase(createLeaveRequest.fulfilled, (state, action) => {
        state.LeaveRequestButtonSpinner = false;
        state.LeaveRequestErrors = {}
      }).addCase(createLeaveRequest.rejected, (state, action) => {
        state.LeaveRequestShowMessage = true;
        state.LeaveRequestButtonSpinner = false;
        state.LeaveRequestErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getLeaveRequest.pending, (state, action) => {
        state.LeaveRequestButtonSpinner = true;
        state.LeaveRequestTableLoading = true;
      }).addCase(getLeaveRequest.fulfilled, (state, action) => {
        state.LeaveRequestButtonSpinner = false;
        state.LeaveRequestTableLoading = false;
        state.LeaveRequestResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getLeaveRequest.rejected, (state, action) => {
        state.LeaveRequestShowMessage = true; // Set the showMessage flag to display the errors
        state.LeaveRequestButtonSpinner = false;
        state.LeaveRequestTableLoading = false;
        state.LeaveRequestErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getAttendanceCode.pending, (state, action) => {
      }).addCase(getAttendanceCode.fulfilled, (state, action) => {
        state.AttendanceCode = action.payload;
      }).addCase(getAttendanceCode.rejected, (state, action) => {
      })

      .addCase(viewLeaveRequest.pending, (state, action) => {
        state.ViewLeaveRequestLoader = true;
        state.ViewLeaveRequestData = [];
      }).addCase(viewLeaveRequest.fulfilled, (state, action) => {
        state.ViewLeaveRequestLoader = false;
        state.ViewLeaveRequestData = action.payload;
      }).addCase(viewLeaveRequest.rejected, (state, action) => {
        state.ViewLeaveRequestLoader = false;
        state.ViewLeaveRequestData = [];
      })
      .addCase(deleteLeaveRequest.pending, (state, action) => {

      }).addCase(deleteLeaveRequest.fulfilled, (state, action) => {

      }).addCase(deleteLeaveRequest.rejected, (state, action) => {

      });
  },
});

export const { setColumnSearch, LeaveRequestAddDrawerStatus, LeaveRequestEditWithDrawerStatus, updateSortFilters } = manageLeaveRequestSlice.actions;

export default manageLeaveRequestSlice.reducer;
