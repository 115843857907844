import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_CALENDAR_EVENTS, DASHBOARD, GET_NOTIFICATION_DATA, GET_MONTHLY_ATTENDANCE, CLEAR_NOTIFICATION, GET_ENROLLMENT_DATA, GET_COUNTS, GET_STUDENT_BIRTHDAYS, GET_GOOGLE_CLASSROOM_STATUS } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  permission: permission?.course ?? [],
  dashboard: '',
  studentBirthdayLoading: true,
  studentBirthdays: [],
  googleClassroomStatusLoading: true,
  googleClassroomStatus: false,
  counts: [],
  countLoading: true,
  enrollmentArray: [],
  enrollmentLoading: true,
  monthlyAttendance: [],
  attendanceLoading: true,
  notificationData: [],
  notificationDataLoading: true,
  openDrawer3: false,
  openDrawer4: false,
  calendarEventLoading: true,
  calendarEvents: [],
};

export const getCounts = createAsyncThunk(
  "getCounts",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_COUNTS);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getEnrollmentData = createAsyncThunk(
  "getEnrollmentData",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ENROLLMENT_DATA);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const clearNotification = createAsyncThunk(
  "clearNotification",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, CLEAR_NOTIFICATION);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getMonthlyAttendance = createAsyncThunk(
  "getMonthlyAttendance",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_MONTHLY_ATTENDANCE);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getNotificationData = createAsyncThunk(
  "getNotificationData",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_NOTIFICATION_DATA);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentBirthdays = createAsyncThunk(
  "getStudentBirthdays",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_STUDENT_BIRTHDAYS);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCalendarActivity = createAsyncThunk(
  "getCalendarActivity",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_CALENDAR_EVENTS);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getGoogleClassroomStatus = createAsyncThunk(
  "getGoogleClassroomStatus",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_GOOGLE_CLASSROOM_STATUS);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const dashboardSlice = createSlice({
  name: DASHBOARD,
  initialState,
  reducers: {
    setOpenDrawer3: (state, action) => {
      state.openDrawer3 = action.payload;
    },
    setOpenDrawer4: (state, action) => {
      state.openDrawer4 = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCounts.pending, (state, action) => {
        state.countLoading = true;
      }).addCase(getCounts.fulfilled, (state, action) => {
        state.countLoading = false;
        state.counts = action.payload;
      }).addCase(getCounts.rejected, (state, action) => {
        state.countLoading = false;
      })

      .addCase(getEnrollmentData.pending, (state, action) => {
        state.enrollmentLoading = true;
      }).addCase(getEnrollmentData.fulfilled, (state, action) => {
        state.enrollmentLoading = false;
        state.enrollmentArray = {
          series: [
            {
              name: "Enrollments",
              data: action.payload && action.payload?.series_data ? action.payload?.series_data : [],
            },
          ],
          categories: action.payload && action.payload?.categories ? action.payload?.categories : [],
        };

      }).addCase(getEnrollmentData.rejected, (state, action) => {
        state.enrollmentLoading = false;
      })

      .addCase(clearNotification.pending, (state, action) => {
        state.notificationDataLoading = true;
      }).addCase(clearNotification.fulfilled, (state, action) => {
        state.notificationDataLoading = false;
        state.notificationData = action.payload;
      }).addCase(clearNotification.rejected, (state, action) => {
        state.notificationDataLoading = false;
      })

      .addCase(getNotificationData.pending, (state, action) => {
        state.notificationDataLoading = true;
      }).addCase(getNotificationData.fulfilled, (state, action) => {
        state.notificationDataLoading = false;
        state.notificationData = action.payload;
      }).addCase(getNotificationData.rejected, (state, action) => {
        state.notificationDataLoading = false;
      })

      .addCase(getStudentBirthdays.pending, (state, action) => {
        state.studentBirthdayLoading = true;
      }).addCase(getStudentBirthdays.fulfilled, (state, action) => {
        state.studentBirthdayLoading = false;
        state.studentBirthdays = action.payload;
      }).addCase(getStudentBirthdays.rejected, (state, action) => {
        state.studentBirthdayLoading = false;
      })

      .addCase(getGoogleClassroomStatus.pending, (state, action) => {
        state.googleClassroomStatusLoading = true;
      }).addCase(getGoogleClassroomStatus.fulfilled, (state, action) => {
        state.googleClassroomStatusLoading = false;
        state.googleClassroomStatus = action.payload;
      }).addCase(getGoogleClassroomStatus.rejected, (state, action) => {
        state.googleClassroomStatusLoading = false;
      })

      .addCase(getMonthlyAttendance.pending, (state, action) => {
        state.attendanceLoading = true;
      }).addCase(getMonthlyAttendance.fulfilled, (state, action) => {
        state.attendanceLoading = false;
        state.monthlyAttendance = action.payload;
      }).addCase(getMonthlyAttendance.rejected, (state, action) => {
        state.attendanceLoading = false;
      })
      .addCase(getCalendarActivity.pending, (state, action) => {
        state.calendarEventLoading = true;
      }).addCase(getCalendarActivity.fulfilled, (state, action) => {
        state.calendarEventLoading = false;
        state.calendarEvents = action.payload;
      }).addCase(getCalendarActivity.rejected, (state, action) => {
        state.calendarEventLoading = false;
      });
  },
});

export const { setOpenDrawer3, setOpenDrawer4 } = dashboardSlice.actions;

export default dashboardSlice.reducer;
