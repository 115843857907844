import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {CITY, CITY_API_URL, GET_ALL_CITY_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  CityErrors       : {},
  permission: permission?.city ?? [],
  CityShowMessage  : null,
  CityResult       : [],
  CityButtonSpinner : false,
  CityTableLoading : true,
  CityAddDrawer    : false,
  CityEditData     : [],
  CityButtonAndModelLabel  : setLocale('city.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    DrawerStatus: 0,
};

export const createCity = createAsyncThunk(
    "createCity",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, CITY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCity = createAsyncThunk(
    "getCity",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_CITY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteCity = createAsyncThunk(
  "deleteCity",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CITY_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageCitySlice = createSlice({
  name: CITY,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.CityErrors = {};
    },
    CityAddDrawerStatus: (state, action) => {
      // state.CityErrors      = {};
      state.CityAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.CityEditData    = [];
      state.CityButtonAndModelLabel = setLocale('city.add');
    },
    CityEditWithDrawerStatus: (state, action) => {
      // state.CityErrors = {};
      state.CityAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.CityEditData = action.payload.data;
      state.CityButtonAndModelLabel = setLocale('city.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCity.pending, (state, action) => {
        state.DrawerStatus = 0
        state.CityButtonSpinner = true;
      }).addCase(createCity.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.CityButtonSpinner = false;
        state.CityErrors = {}
      }).addCase(createCity.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.CityShowMessage = true;
        state.CityButtonSpinner = false;
        state.CityErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getCity.pending, (state, action) => {
        state.CityButtonSpinner = true;
        state.CityTableLoading = true;
      }).addCase(getCity.fulfilled, (state, action) => {
        state.CityButtonSpinner = false;
        state.CityTableLoading = false;
        state.CityResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.total,
            current: action.payload.current_page,
            pageSize: action.payload.per_page
        };
      }).addCase(getCity.rejected, (state, action) => {
        state.CityShowMessage = true; // Set the showMessage flag to display the errors
        state.CityButtonSpinner = false;
        state.CityTableLoading = false;
        state.CityErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(deleteCity.pending, (state, action) => {

      }).addCase(deleteCity.fulfilled, (state, action) => {

      }).addCase(deleteCity.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, CityAddDrawerStatus, CityEditWithDrawerStatus, updateSortFilters } = manageCitySlice.actions;

export default manageCitySlice.reducer;
