import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {TIMEZONE_CODE, TIMEZONE_CODE_API_URL, GET_ALL_TIMEZONE_CODE_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  TimezoneCodeErrors       : {},
  permission: permission?.timezone_code ?? [],
  TimezoneCodeShowMessage  : null,
  TimezoneCodeResult       : [],
  TimezoneCodeButtonSpinner : false,
  TimezoneCodeTableLoading : true,
  TimezoneCodeAddDrawer    : false,
  TimezoneCodeEditData     : [],
  TimezoneCodeButtonAndModelLabel  : setLocale('timezonecode.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewTimezoneCodeData: [],
    ViewTimezoneCodeLoader: true,
    DrawerStatus: 0,
};

export const createTimezoneCode = createAsyncThunk(
    "createTimezoneCode",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, TIMEZONE_CODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getTimezoneCode = createAsyncThunk(
    "getTimezoneCode",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_TIMEZONE_CODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewTimezoneCode = createAsyncThunk(
  "viewTimezoneCode",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, TIMEZONE_CODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteTimezoneCode = createAsyncThunk(
  "deleteTimezoneCode",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, TIMEZONE_CODE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageTimezoneCodeSlice = createSlice({
  name: TIMEZONE_CODE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.TimezoneCodeErrors = {};
    },
    TimezoneCodeAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.TimezoneCodeButtonSpinner = false;
      }
      // state.TimezoneCodeErrors      = {};
      state.TimezoneCodeAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.TimezoneCodeEditData    = [];
      state.TimezoneCodeButtonAndModelLabel = setLocale('timezonecode.add');
    },
    TimezoneCodeEditWithDrawerStatus: (state, action) => {
      // state.TimezoneCodeErrors = {};
      state.TimezoneCodeAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.TimezoneCodeEditData = action.payload.data;
      state.TimezoneCodeButtonAndModelLabel = setLocale('timezonecode.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTimezoneCode.pending, (state, action) => {
        state.DrawerStatus = 0
        state.TimezoneCodeButtonSpinner = true;
      }).addCase(createTimezoneCode.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.TimezoneCodeButtonSpinner = false;
        state.TimezoneCodeErrors = {}
      }).addCase(createTimezoneCode.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.TimezoneCodeShowMessage = true;
        state.TimezoneCodeButtonSpinner = false;
        state.TimezoneCodeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getTimezoneCode.pending, (state, action) => {
        state.TimezoneCodeButtonSpinner = true;
        state.TimezoneCodeTableLoading = true;
      }).addCase(getTimezoneCode.fulfilled, (state, action) => {
        state.TimezoneCodeButtonSpinner = false;
        state.TimezoneCodeTableLoading = false;
        state.TimezoneCodeResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.total,
            current: action.payload.current_page,
            pageSize: action.payload.per_page
        };
      }).addCase(getTimezoneCode.rejected, (state, action) => {
        state.TimezoneCodeShowMessage = true; // Set the showMessage flag to display the errors
        state.TimezoneCodeButtonSpinner = false;
        state.TimezoneCodeTableLoading = false;
        state.TimezoneCodeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewTimezoneCode.pending, (state, action) => {
        state.ViewTimezoneCodeLoader = true;
        state.ViewTimezoneCodeData = [];
      }).addCase(viewTimezoneCode.fulfilled, (state, action) => {
        state.ViewTimezoneCodeLoader = false;
        state.ViewTimezoneCodeData = action.payload;
      }).addCase(viewTimezoneCode.rejected, (state, action) => {
        state.ViewTimezoneCodeLoader = false;
        state.ViewTimezoneCodeData = [];
      })
      .addCase(deleteTimezoneCode.pending, (state, action) => {

      }).addCase(deleteTimezoneCode.fulfilled, (state, action) => {

      }).addCase(deleteTimezoneCode.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, TimezoneCodeAddDrawerStatus, TimezoneCodeEditWithDrawerStatus, updateSortFilters } = manageTimezoneCodeSlice.actions;

export default manageTimezoneCodeSlice.reducer;
