import fetch from "auth/FetchInterceptor";

const CommonService = {};

CommonService.createAndUpdate = function (data, request_url) {
  if (data && data.hasOwnProperty("id")) {
    return fetch({
      url: `${request_url}/${data.id}`,
      method: "put",
      data,
    });
  } else if (data instanceof FormData && data.get("id")) {
    const formData = logFormData(data);
    return fetch({
      url: `${request_url}/${data.get("id")}`,
      method: "put",
      formData,
    });
  } else {
    return fetch({
      url: `${request_url}`,
      method: "post",
      data,
    });
  }
};
//
// Function to log the contents of FormData
const logFormData = (formData) => {
  let keys = [];
  for (const pair of formData.entries()) {
    keys[pair[0]] = pair[1];
    // console.log(`${pair[0]}: ${pair[1]}`);
  }
  return keys;
};
CommonService.getAll = function (request_url, data) {
  return fetch({
    url: `${request_url}`,
    method: "get",
    data: data,
  });
};

//
CommonService.getAllPost = function (data, request_url) {
  return fetch({
    url: `${request_url}`,
    method: "post",
    data,
  });
};

//
CommonService.showOne = function (id, request_url) {
  return fetch({
    url: `${request_url}/${id}`,
    method: "get",
  });
};
//
CommonService.deleteOne = function (id, request_url) {
  return fetch({
    url: `${request_url}/${id}`,
    method: "delete",
  });
};

CommonService.postData = function (data, URL) {
  return fetch({
    url: `${URL}`,
    method: "post",
    data,
  });
};
CommonService.putData = function (data, URL) {
  if (data && data.hasOwnProperty("id")) {
    return fetch({
      url: `${URL}/${data.id}`,
      method: "put",
      data,
    });
  } else if (data instanceof FormData && data.get("id")) {
    return fetch({
      url: `${URL}/${data.get("id")}`,
      method: "put",
      data,
    });
  }
};
//
/**get Data in CommonService Service */
CommonService.getData = function (data, URL) {
  /**if some pagination or some url params */
  const queryParams = new URLSearchParams(data);
  const url = data ? `/${URL}?${queryParams.toString()}` : `/${URL}`;
  return fetch({
    url: `${url}`,
    method: "get",
    data: data,
  });
};
//
CommonService.getSingleData = function (id, URL) {
  return fetch({
    url: `${URL}/${id}`,
    method: "get",
  });
};
//
CommonService.deleteData = function (id, URL) {
  return fetch({
    url: `${URL}/${id}`,
    method: "delete",
  });
};

export default CommonService;
