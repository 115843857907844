import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {MANAGE_SCHOOL, MANAGE_SCHOOL_API_URL, GET_ALL_MANAGE_SCHOOL_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ManageSchoolErrors       : {},
  permission: permission?.manage_school ?? [],
  ManageSchoolShowMessage  : null,
  ManageSchoolResult       : [],
  ManageSchoolButtonSpinner : false,
  ManageSchoolTableLoading : true,
  ManageSchoolAddDrawer    : false,
  ManageSchoolEditData     : [],
  ManageSchoolButtonAndModelLabel  : setLocale('manageschool.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewManageSchoolData: [],
    ViewManageSchoolLoader: true,
};

export const createManageSchool = createAsyncThunk(
    "createManageSchool",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, MANAGE_SCHOOL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getManageSchool = createAsyncThunk(
    "getManageSchool",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_MANAGE_SCHOOL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewManageSchool = createAsyncThunk(
  "viewManageSchool",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, MANAGE_SCHOOL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteManageSchool = createAsyncThunk(
  "deleteManageSchool",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, MANAGE_SCHOOL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageManageSchoolSlice = createSlice({
  name: MANAGE_SCHOOL,
  initialState,
  reducers: {
    ManageSchoolAddDrawerStatus: (state, action) => {
      state.ManageSchoolErrors      = {};
      state.ManageSchoolAddDrawer   = action.payload;
      state.ManageSchoolEditData    = [];
      state.ManageSchoolButtonAndModelLabel = setLocale('manageschool.add');
    },
    ManageSchoolEditWithDrawerStatus: (state, action) => {
      state.ManageSchoolErrors = {};
      state.ManageSchoolAddDrawer = true;
      state.ManageSchoolEditData = action.payload;
      state.ManageSchoolButtonAndModelLabel = setLocale('manageschool.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createManageSchool.pending, (state, action) => {
        state.ManageSchoolButtonSpinner = true;
      }).addCase(createManageSchool.fulfilled, (state, action) => {
        state.ManageSchoolButtonSpinner = false;
        state.ManageSchoolErrors = {}
      }).addCase(createManageSchool.rejected, (state, action) => {
        state.ManageSchoolShowMessage = true;
        state.ManageSchoolButtonSpinner = false;
        state.ManageSchoolErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getManageSchool.pending, (state, action) => {
        state.ManageSchoolButtonSpinner = true;
        state.ManageSchoolTableLoading = true;
      }).addCase(getManageSchool.fulfilled, (state, action) => {
        state.ManageSchoolButtonSpinner = false;
        state.ManageSchoolTableLoading = false;
        state.ManageSchoolResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.total,
            current: action.payload.current_page,
            pageSize: action.payload.per_page
        };
      }).addCase(getManageSchool.rejected, (state, action) => {
        state.ManageSchoolShowMessage = true; // Set the showMessage flag to display the errors
        state.ManageSchoolButtonSpinner = false;
        state.ManageSchoolTableLoading = false;
        state.ManageSchoolErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewManageSchool.pending, (state, action) => {
        state.ViewManageSchoolLoader = true;
        state.ViewManageSchoolData = [];
      }).addCase(viewManageSchool.fulfilled, (state, action) => {
        state.ViewManageSchoolLoader = false;
        state.ViewManageSchoolData = action.payload;
      }).addCase(viewManageSchool.rejected, (state, action) => {
        state.ViewManageSchoolLoader = false;
        state.ViewManageSchoolData = [];
      })
      .addCase(deleteManageSchool.pending, (state, action) => {

      }).addCase(deleteManageSchool.fulfilled, (state, action) => {

      }).addCase(deleteManageSchool.rejected, (state, action) => {

      });
  },
});

export const {  ManageSchoolAddDrawerStatus, ManageSchoolEditWithDrawerStatus, updateSortFilters } = manageManageSchoolSlice.actions;

export default manageManageSchoolSlice.reducer;
