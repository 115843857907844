export const ATTENDANCE_SLICE = 'attendanceSlice'
export const ATTENDANCE_API_URL = 'attendance'
export const SAVE_BULK_ATT_MODIFICATION = 'save-bulk-att-modification'
export const GET_ATT_FOR_MODIFICATION = 'get-att-for-modification'
export const GET_CLASS_STUDENTS_FOR_ATT = 'get-class-students-for-attendance'
export const MARK_ATTENDANCE_FULL_CLASS = 'mark-attendance-full-class'
export const MODIFY_SINGLE_STUDENT_ATT = 'modify-single-student-att'
export const MARK_BULK_STUDENT_ATT = 'mark-bulk-student-att'
export const GET_ATTENDANCE_CODES = 'get-attendance-codes'
export const GET_ATTENDANCE_LATEST_POLICY = 'get-attendance-lates-policy'
