import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ORGANIZATION_TYPES, ORGANIZATION_TYPES_API_URL, GET_ALL_ORGANIZATION_TYPES_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
import { Link } from "react-router-dom";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  OrganizationTypeErrors: {},
  permission: permission?.organization_type ?? [],
  OrganizationTypeShowMessage: null,
  OrganizationTypeResult: [],
  OrganizationTypeButtonSpinner: false,
  OrganizationTypeTableLoading: true,
  OrganizationTypeAddDrawer: false,
  OrganizationTypeEditData: [],
  OrganizationTypeButtonAndModelLabel: setLocale('organization_type.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  DrawerStatus: 0,
};

export const createOrganizationType = createAsyncThunk(
  "createOrganizationType",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, ORGANIZATION_TYPES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getOrganizationType = createAsyncThunk(
  "getOrganizationType",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_ORGANIZATION_TYPES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteOrganizationType = createAsyncThunk(
  "deleteOrganizationType",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ORGANIZATION_TYPES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageOrganizationTypeSlice = createSlice({
  name: ORGANIZATION_TYPES,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.OrganizationTypeErrors = {};
    },
    OrganizationTypeAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.OrganizationTypeButtonSpinner = false;
      }
      // state.OrganizationTypeErrors = {};
      state.OrganizationTypeAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.OrganizationTypeEditData = [];
      state.OrganizationTypeButtonAndModelLabel = setLocale('organization_type.add');
    },
    OrganizationTypeEditWithDrawerStatus: (state, action) => {
      // state.OrganizationTypeErrors = {};
      state.OrganizationTypeAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.OrganizationTypeEditData = action.payload.data;
      state.OrganizationTypeButtonAndModelLabel = setLocale('organization_type.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrganizationType.pending, (state, action) => {
        state.DrawerStatus = 0
        state.OrganizationTypeButtonSpinner = true;
      }).addCase(createOrganizationType.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.OrganizationTypeButtonSpinner = false;
        state.OrganizationTypeErrors = {}
      }).addCase(createOrganizationType.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.OrganizationTypeShowMessage = true;
        state.OrganizationTypeButtonSpinner = false;
        state.OrganizationTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getOrganizationType.pending, (state, action) => {
        state.OrganizationTypeButtonSpinner = true;
        state.OrganizationTypeTableLoading = true;
      }).addCase(getOrganizationType.fulfilled, (state, action) => {
        state.OrganizationTypeButtonSpinner = false;
        state.OrganizationTypeTableLoading = false;
        state.OrganizationTypeResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getOrganizationType.rejected, (state, action) => {
        state.OrganizationTypeShowMessage = true; // Set the showMessage flag to display the errors
        state.OrganizationTypeButtonSpinner = false;
        state.OrganizationTypeTableLoading = false;
        state.OrganizationTypeErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(deleteOrganizationType.pending, (state, action) => {

      }).addCase(deleteOrganizationType.fulfilled, (state, action) => {

      }).addCase(deleteOrganizationType.rejected, (state, action) => {

      });
  },
});

export const {onCloseError, setColumnSearch, OrganizationTypeAddDrawerStatus, OrganizationTypeEditWithDrawerStatus, updateSortFilters } = manageOrganizationTypeSlice.actions;

export default manageOrganizationTypeSlice.reducer;
